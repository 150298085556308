import React from "react";
import "./approvedPayment.css";
import logo from "./logo.png";
import instagram from "./instagram.png";
import facebook from "./facebook.png";
import youtube from "./youtube.png";
import mosaic from "./mosaique.png";


export default function ApprovedPayment() {
  return (
    <>
      <header className="email-template-header">
        <img src={logo} alt="Telecall" className="logo" />
      </header>

      <main className="content-email-template">
        <div className="">
          <p className="m0">Olá [Nome Cliente],</p>
          {/* <br /> */}
          <table className="">
            <tbody>
              <tr>
                <td className="">
                  <p className="content-p">Temos boas notícias, seu pedido foi aprovado com sucesso!</p>
                  <p className="content-p">Confira os detalhes abaixo</p>

                  <p className="content-p"><span className="bold-text">Plano:</span> Mensal - R$ 39,99</p>
                  <p ><span className="bold-text">Forma de Pagamento:</span> visa 3440</p>
                  <p className="content-p">O que você esta esperando? <a className="template-btn" href="http://" target="_blank" rel="noopener noreferrer">Comece a jogar agora!</a></p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p className="css.m0">Obrigado.</p>
          <p className="jaw-team"><span className="bold-text">Equipe Jaw Games.</span></p>
        </div>
      </main>

      <div className="mosaic-section">
        <img alt="" className="mosaic-img" src={mosaic} />
      </div>

      <footer className="footer-email">
        <div className="footerWrapper">
          <div>
            <img alt="" className="logo" src={logo} />
          </div>

          <div className="footerNav">
            <nav>
              <ul>
                <li>
                  <p>&reg; Jaw James</p>
                </li>
                <li>
                  <p>Politica de Privacidade</p>
                </li>
              </ul>
            </nav>
          </div>

          <div className="footerSocialMedia">
            <ul>
              <li>
                <a
                  href="https://facebook.com/playjaw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="" src={facebook} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/jaw.games"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="" src={instagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCmPYgyEUc6TI_Btel3j5p-Q"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="" src={youtube} />{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}