import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  FloatingLabel,
  Button,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import "../styles/emailRegister.css";
import BackImage from "../images/webdoor.png";
import LogoAzul from "../images/logo-azul.png";
import useDocumentTitle from "../components/useDocumentTitle";
import apijaw from "../services/api-jaw";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import {BackToHomeSite} from "../consts"

export default function EmailRegister(props) {
  const { t, i18n } = useTranslation("email-register");
  useDocumentTitle("email-register");

  let history = useNavigate();
  const goToPreviousPath = () => {
    history(-1);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [sending, setSending] = useState(false);

  const initialForm = {
    email: "",
  };

  const handleSubmitThis = (values) => {
    setSending(true);
    apijaw
      .post(
        "/Customer/EmailEventRegistrations",
        {
          email: values.email,
        },
        {
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      )
      .then((response) => {
        setSending(false);
        if (response.data.success === true) {
          Swal.fire({
            icon: "success",
            text: t("successMessage"),
          }).then(function (result) {
            window.location.href = BackToHomeSite
          });
        } else {
          Swal.fire({
            icon: "info",
            text: t("couldntSendRequestMessage"),
          });
        }
      })
      .catch((error) => {
        setSending(false);
        if (error.response?.data?.errors != undefined) {
          let message =
            "<p>" +
            error.response.data.errors.map((e) => e.message).join("</p><p>") +
            "</p>";
          Swal.fire({
            icon: "error",
            html: message,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: t("requestFaliureMessage"),
          });
        }
      });
  };

  const schema = yup.object().shape({
    email: yup.string().required().email(),
  });

  return (
    <>
      <div className="register-container">
        <div
          className="register-webdoor"
          style={{
            backgroundImage: `url(${BackImage})`,
          }}
        >
          <div className="content">
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={handleSubmitThis}
              initialValues={initialForm}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <p style={{ fontSize: "1.4em" }} className="text-center">
                    <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                  </p>
                  <p style={{ fontSize: "1.4em" }} className="text-center">
                    {t("registerMsg")}
                  </p>

                  <FloatingLabel
                    controlId="flEmail"
                    label={t("emailLabel")}
                    className="mb-3"
                  >
                    <Form.Control
                      autoFocus
                      type="email"
                      name="email"
                      value={values.email}
                      maxLength={50}
                      onChange={handleChange}
                      isInvalid={errors.email}
                      required
                    />
                  </FloatingLabel>

                  <Row>
                    <Col className="text-center">
                      <a 
                      href={BackToHomeSite}
                        className="btn btn-round outlined mr-3"
                        onClick={BackToHomeSite}
                      >
                        {t("goBackLabel")}
                      </a>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-round"
                        disabled={!isValid || values?.email == undefined}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className={
                            !sending
                              ? "visually-hidden spinner-right-margin"
                              : "spinner-right-margin"
                          }
                        />
                        {t("buttonLabel")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
