import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import api from '../../services/api';
import "../../styles/pageForm.css";
import useDocumentTitle from "../useDocumentTitle";
import "./OtpForm.css";

export default function LoginOtpForm(props) {
    const { t, i18n } = useTranslation('device-auth');

    useDocumentTitle(t('title'))

    let [searchParams, setSearchParams] = useSearchParams();

    const firebaseAuth = getAuth();
    firebaseAuth.useDeviceLanguage();

    const [form, setForm] = useState({
        authCode: "",
    });

    const [loadingSubmitCode, setLoadingSubmitCode] = useState(false);
    const [exibirMensagem, setExibirMensagem] = useState(false);
    const [alerta, setAlerta] = useState({ mensagem: "", variant: "info" });

    const submitForm = () => {
        if (!form.authCode || form.authCode.length < 8) return;
        setLoadingSubmitCode(true);
        api.get(`/deviceAuth/${form.authCode}`)
            .then(() => {
                setSearchParams({ code: form.authCode })
            })
            .catch(() => {
                setExibirMensagem(true);
                setAlerta({ mensagem: t('invalidCode'), variant: "danger" });
            })
            .finally(() => {
                setLoadingSubmitCode(false);
            });
    };

    const handleFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setExibirMensagem(false);

        setForm((prevState) => ({
            ...prevState,
            [name]: value.toUpperCase(),
        }));
    }

    return (<>
        {exibirMensagem ? <Alerta mensagem={alerta.mensagem} variant={alerta.variant} /> : null}
        <Form.Label>
            <p>{t('description')}</p>
        </Form.Label>

        <Form className='otpForm'>
            <FloatingLabel
                controlId="flauthCode"
            >
                <Form.Control
                    name="authCode"
                    value={form.authCode}
                    onChange={handleFormChange}
                    maxLength={8}
                    required
                    style={{ fontSize: '32px', textAlign: 'center', letterSpacing: '10px', fontWeight: '700' }}
                />
            </FloatingLabel>

            <Button
                style={{ backgroundColor: "#26AAE2" }}
                size="lg"
                onClick={() => submitForm()}
            >
                {loadingSubmitCode ? <span><FontAwesomeIcon spin={true} icon={faSpinner} /></span> : t('buttonLabel')}
            </Button>
        </Form>
    </>)
}

const Alerta = (props) => (
    <Alert variant={props.variant} className="text-center" style={{ width: '100%' }}>
        <div dangerouslySetInnerHTML={{ __html: props.mensagem }} />
    </Alert>
)