import React from "react";
import "./orderFulfilled.css";
import {
  YoutubeLogo,
  InstagramLogo,
  FacebookLogo,
  Logo,
  Mosaic,
} from "../logos";

export default function OrderFulfilled() {
  return (
    <>
      <header className="email-template-header">
        <Logo />
      </header>

      <main className="content-email-template">
        <div className="">
          <p className="m0">Olá [Nome Cliente],</p>
          <br />
          <table className="">
            <tbody>
              <tr>
                <td className="">
                  <p className="content-p">Obrigado por se cadastrar na Jaw Games!</p>

                  <p>Seu pedido foi realizado e está sendo processado. imaginamos que você já está ansioso para jogar, por isso agradecemos desde já a compreensão enquanto confirmamos os dados do seu cadastro. Te notificaremos em breve!</p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p className="css.m0">Obrigado.</p>
          <p className="jaw-team">Equipe Jaw Games.</p>
        </div>
      </main>

      <div className="mosaic-section">
        <Mosaic />
      </div>

      <footer className="footer-email">
        <div className="footerWrapper">
          <div>
            <Logo />
          </div>

          <div className="footerNav">
            <nav>
              <ul>
                <li>
                  <p>&reg; Jaw James</p>
                </li>
                <li>
                  <p>Politica de Privacidade</p>
                </li>
              </ul>
            </nav>
          </div>

          <div className="footerSocialMedia">
            <ul>
              <li>
                <FacebookLogo />
              </li>
              <li>
                <InstagramLogo />
              </li>
              <li>
                <YoutubeLogo />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
