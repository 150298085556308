import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useQueryParams from "../components/useQueryParams";
import { Container, Row, Col, Form, Button, InputGroup, Image, Spinner, Alert, Dropdown } from "react-bootstrap";
import InputMask from "react-input-mask";
import { Formik } from "formik";
import * as yup from "yup";
import StepsPill from "../components/stepsPill";
import styles from "./payment.module.css";
import logo from "../images/logo-azul.png";
import Swal from "sweetalert2";
import VisaLogo from "../images/Visa.png";
import MastercardLogo from "../images/Mastercard.png";
import { CompanyId, PaymentGatewayType, PayPalClientId } from "../consts";
import CreditCardIcon from "../images/creditcard.png";
import apijaw from "../services/api-jaw";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import dateFormat from 'dateformat';
import ChangeLanguages from '../components/changeLanguage'

export default function Payment(props) {
	const { t, i18n } = useTranslation("payment-page");
	const { id } = useParams();
	const queryParams = useQueryParams();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	}

	const brands = [
		{ id: 0, name: "Visa", image: VisaLogo },
		{ id: 1, name: "Mastercard", image: MastercardLogo },
	];

	const [step, setStep] = useState({
		current: 2,
		total: 4,
	});

	const [customer, setCustomer] = useState({});

	const [paymentGateways, setPaymentGateways] = useState([]);

	const [products, setProducts] = useState([]);

	const [form, setForm] = useState({
		sending: false,
		paymentGatewayId: "",
		paymentGatewayType: null,
		productId:
			queryParams.get("productId") !== null ? queryParams.get("productId") : "",
		discountCoupon: "",
		discountCouponIsInvalid: {
			touched: false,
			invalid: false,
		},
		creditCardNumber: "",
		creditCardBrandId: "",
		creditCardHolderName: "",
		creditCardGoodThru: "",
		creditCardCvv: "",
	});

	useEffect(() => {
		initialLoad();
	}, []);

	const initialLoad = async () => {

		const customerRequest = getCustomer();
		let customerData;

		const currentSubscription = getCurrentSubscription();

		const paymentGatewaysRequest = getPaymentGateways();
		let paymentGatewaysData;

		const productsRequest = getProducts();
		let productsData;

		try {
			const response = await customerRequest;
			if (
				response.data.success &&
				response.data?.data?.customer != undefined &&
				response.data?.data?.customer.companyId == CompanyId
			) {
				customerData = response.data.data.customer;
			} else {
				return Swal.fire({
					icon: "error",
					text: t("messages.errors.invalidUser"),
				}).then(function (result) {
					window.location.href = "/";
				});
			}
		} catch (error) {
			return Swal.fire({
				icon: "error",
				text: t("messages.errors.invalidUser"),
			}).then(function (result) {
				window.location.href = "/";
			});
		}

		try {
			const response = await currentSubscription;
			var formatter = new Intl.DateTimeFormat('pt-BR');
			if (response.data?.currentSubscription !== undefined
				&& response.data?.currentSubscription !== null) {
				console.log(dateFormat(response.data.data.currentSubscription.finalDate, "dddd, mmmm dS, yyyy"))
				return Swal.fire({
					icon: "error",
					text: t("messages.errors.subscriptionActive"),
					text: t("messages.errors.subscriptionFinalDate") + formatter.format(response.data.data?.currentSubscription.finalDate)
				}).then(function (result) {
					window.location.href = "/";
				});
			}
		} catch (error) {
			return Swal.fire({
				icon: "error",
				text: t("messages.errors.unableToCheckSubscription"),
			}).then(function (result) {
				window.location.href = "/";
			});
		}

		try {
			const response = await paymentGatewaysRequest;

			if (
				response.data.success &&
				response.data.data.paymentGateways.length > 0
			) {
				paymentGatewaysData = response.data.data.paymentGateways;
			} else {
				return Swal.fire({
					icon: "error",
					text: t("messages.errors.noPaymentGatewayAvailable"),
				}).then(function (result) {
					window.location.href = "/";
				});
			}
		} catch (error) {
			return Swal.fire({
				icon: "error",
				text: t("messages.errors.noPaymentGatewayAvailable"),
			}).then(function (result) {
				window.location.href = "/";
			});
		}

		try {
			const response = await productsRequest;
			if (response.data.success && response.data.data.products.length > 0) {
				productsData = response.data.data.products;
				if (queryParams.get("productId") !== null && productsData.find(x => x.id == queryParams.get("productId")) === undefined) {
					window.location.href = `/payment/${id}`;
				}
			} else {
				return Swal.fire({
					icon: "error",
					text: t("messages.errors.noProductAvailable"),
				}).then(function (result) {
					window.location.href = "/";
				});
			}
		} catch (error) {
			return Swal.fire({
				icon: "error",
				text: t("messages.errors.noProductAvailable"),
			}).then(function (result) {
				window.location.href = "/";
			});
		}

		setCustomer(customerData);
		setProducts(productsData);
		setPaymentGateways(paymentGatewaysData);
	};

	const getCustomer = async () => {
		return await apijaw.get("/Customer/" + id, {
			headers: {
				"Accept-Language": i18n.language,
			},
		});
	};

	const getCurrentSubscription = async () => {
		return await apijaw.get(`/Subscription/Current/${id}`, {
			headers: {
				"Accept-Language": i18n.language,
			},
		});
	};

	const getProducts = async () => {
		return await apijaw.get("/Product/Search?companyId=" + CompanyId, {
			headers: {
				"Accept-Language": i18n.language,
			},
		});
	};

	const getPaymentGateways = async () => {
		return await apijaw.get("/PaymentGateway/Search?companyId=" + CompanyId, {
			headers: {
				"Accept-Language": i18n.language,
			},
		});
	};

	const getValidateCoupon = async (productId, countryId, coupon) => {
		return await apijaw.get(
			`/Coupon/Validation/${CompanyId}/${productId}/${countryId}/${coupon}`,
			{
				headers: {
					"Accept-Language": i18n.language,
				},
			}
		);
	};

	const handleSubmit = (values) => {
		setForm({
			...values,
			sending: true,
		});

		apijaw
			.post(
				"/Subscription",
				{
					productId: values.productId,
					customerId: id,
					paymentGatewayId: values.paymentGatewayId,
					creditCard: {
						customerId: id,
						number: removeNonNumeric(values.creditCardNumber),
						brand: values.creditCardBrandId,
						cardHolderName: values.creditCardHolderName.toUpperCase(),
						goodThru: parseDate(values.creditCardGoodThru),
						cvv: removeNonNumeric(values.creditCardCvv),
					},
					discountCoupon: values.discountCoupon,
				},
				{
					headers: {
						"Accept-Language": i18n.language,
					},
				}
			)
			.then((response) => {
				setForm((prevState) => ({
					...prevState,
					sending: false,
				}));
				if (response.data.success === true) {
					Swal.fire({
						icon: "success",
						text:
							(values.discountCoupon.length > 0
								? `${t("messages.success.couponProcessed")}\n`
								: "") + t("messages.success.subscriptionMade"),
					}).then(function (result) {
						if (!customer.emailConfirmed) {
							window.location.href = "/email-confirmation/" + id;
						} else {
							window.location.href = "/";
						}
					});
				} else {
					Swal.fire({
						icon: "info",
						text: t("messages.errors.couldntSendRequest"),
					});
				}
			})
			.catch((error) => {
				setForm((prevState) => ({
					...prevState,
					sending: false,
				}));
				if (error.response?.data?.errors != undefined) {
					let message =
						"<p>" +
						error.response.data.errors.map((e) => e.message).join("</p><p>") +
						"</p>";
					Swal.fire({
						icon: "error",
						html: message,
					}).then(function (result) {
						if (
							error.response?.data?.errors.find(
								(x) => x.errorCode === "SubscriptionAlreadyExists"
							)
						) {
							window.location.href = "/";
						}
					});
				} else {
					Swal.fire({
						icon: "error",
						text: t("messages.errors.requestFaliure"),
					});
				}
			});
	};

	const payPalOnAprove = (values, data, actions) => {
		console.log(data, actions);
		if (data?.subscriptionID || data?.orderID) {
			var product = products.find((x) => x.id == values.productId);
			setForm((prevState) => ({
				...prevState,
				sending: true,
			}));
			apijaw
				.post(
					"/Subscription",
					{
						customerId: id,
						productId: values.productId,
						paymentGatewayId: values.paymentGatewayId,
						payPalId: product.isRecurrence ? data.subscriptionID : data.orderID,
					},
					{
						headers: {
							"Accept-Language": i18n.language,
						},
					}
				)
				.then((response) => {
					setForm((prevState) => ({
						...prevState,
						sending: false,
					}));
					if (response.data.success === true) {
						Swal.fire({
							icon: "success",
							text:
								(values.discountCoupon.length > 0
									? `${t("messages.success.couponProcessed")}\n`
									: "") + t("messages.success.subscriptionMade"),
						}).then(function (result) {
							if (!customer.emailConfirmed) {
								window.location.href = "/email-confirmation/" + id;
							} else {
								window.location.href = "/";
							}
						});
					} else {
						Swal.fire({
							icon: "info",
							text: t("messages.errors.couldntSendRequest"),
						});
					}
				})
				.catch((error) => {
					setForm((prevState) => ({
						...prevState,
						sending: false,
					}));
					if (error.response?.data?.errors != undefined) {
						let message =
							"<p>" +
							error.response.data.errors.map((e) => e.message).join("</p><p>") +
							"</p>";
						Swal.fire({
							icon: "error",
							html: message,
						}).then(function (result) {
							if (
								error.response?.data?.errors.find(
									(x) => x.errorCode === "SubscriptionAlreadyExists"
								)
							) {
								window.location.href = "/";
							}
						});
					} else {
						Swal.fire({
							icon: "error",
							text: t("messages.errors.requestFaliure"),
						});
					}
				});
		}
	};

	const validateCoupon = async (productId, coupon) => {
		let result = coupon === undefined || coupon === "" || coupon.length > 0;

		if (
			productId !== undefined &&
			productId !== "" &&
			coupon !== undefined &&
			coupon.length > 0
		) {
			try {
				let countryId = 1;
				let response = await getValidateCoupon(productId, countryId, coupon);
				result = response.data.success;
				if (!response.data.success) {
					Swal.fire({
						icon: "error",
						html: t("messages.errors.invalidCoupon"),
					});
				} else {
					Swal.fire({
						icon: "success",
						title: t("messages.errors.validCoupon"),
						html: t("messages.errors.validCouponMsg"),
					});
				}
			} catch (error) {
				result = false;
				Swal.fire({
					icon: "error",
					html: t("messages.errors.invalidCoupon"),
				});
			}
		}

		return result;
	};

	const removeNonNumeric = (text) => {
		return text?.replace(/\D/g, "");
	};

	const removeDiacritics = (str) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	};

	const creditCardType = (cc) => {
		cc = removeNonNumeric(cc);
		let amex = new RegExp("^3[47][0-9]{13}$");
		let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
		let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
		let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

		let mastercard = new RegExp("^5[1-5][0-9]{14}$");
		let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

		let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
		let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
		let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

		let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
		let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

		if (visa.test(cc)) {
			return brands.find((x) => x.name === "Visa").id;
		}
		/*if (amex.test(cc)) {
		  return 'AMEX';
		}*/
		if (mastercard.test(cc) || mastercard2.test(cc)) {
			return brands.find((x) => x.name === "Mastercard").id;
		}
		/*if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
		  return 'DISCOVER';
		}
		if (diners.test(cc)) {
		  return 'DINERS';
		}
		if (jcb.test(cc)) {
		  return 'JCB';
		}
		if (cup1.test(cc) || cup2.test(cc)) {
		  return 'CHINA_UNION_PAY';
		}*/
		return undefined;
	};

	const parseDate = (value) => {
		if (value !== undefined && removeNonNumeric(value).length === 4) {
			value = value?.replace("_");
			value = new Date(
				`20${value.split("/")[1]}`,
				parseInt(value.split("/")[0], 10) - 1,
				1
			);
		}
		return value;
	};

	const validateCreditCardNumber = (value, context) => {
		return (
			value !== undefined &&
			removeNonNumeric(value).length === 16 &&
			creditCardType(value) !== undefined
		);
	};

	const validateCreditCardBrandId = (value, context) => {
		return (
			value !== undefined && brands.find((x) => x.id == value) !== undefined
		);
	};

	const validateCreditCardHolderName = (value, context) => {
		return value !== undefined && value.length > 0;
	};

	const validateCreditCardGoodThru = (value, context) => {
		let month =
			value !== undefined && value.split("/").length === 2
				? parseInt(value.split("/")[0], 10)
				: 0;
		let year =
			value !== undefined && value.split("/").length === 2
				? parseInt(`20${value.split("/")[1]}`, 10)
				: 0;
		let currentYear = new Date().getFullYear();

		return (
			value !== undefined &&
			value.split("/").length === 2 &&
			month >= 1 &&
			month <= 12 &&
			year >= currentYear &&
			parseDate(value) >= Date.now()
		);
	};

	const validateCreditCardCvv = (value, context) => {
		return value !== undefined && removeNonNumeric(value).length === 3;
	};

	const schema = yup.object().shape({
		productId: yup.string().required(),
		paymentGatewayId: yup.string().required(),
		creditCardNumber: yup
			.string()
			.test(
				"creditCardNumber-test",
				"Número de cartão de crédito é obrigatório ou invalido.",
				(value, context) => validateCreditCardNumber(value, context)
			),
		creditCardBrandId: yup
			.string()
			.test(
				"creditCardBrandId-test",
				"Bandeira do cartão crédito não identificado.",
				(value, context) => validateCreditCardBrandId(value, context)
			),
		creditCardHolderName: yup
			.string()
			.test(
				"creditCardHolderName-test",
				"Nome no cartão de crédito é obrigatório.",
				(value, context) => validateCreditCardHolderName(value, context)
			),
		creditCardGoodThru: yup
			.string()
			.test(
				"creditCardGoodThru-test",
				"Data de vencimento do cartão de crédito de é obrigatório.",
				(value, context) => validateCreditCardGoodThru(value, context)
			),
		creditCardCvv: yup
			.string()
			.test(
				"creditCardCvv-test",
				"Código de segurança do cartão de crédito é obrigatório.",
				(value, context) => validateCreditCardCvv(value, context)
			),
	});

	return (
		<>
			<Container fluid className={[styles.defaults, styles.header].join(" ")}>
				<Row className="justify-content-md-center">
					<Col xs lg="2"></Col>
					<Col md="auto" className="pt-3">
						<img src={logo} className={styles.logo} alt="JAW GAMES" />
					</Col>
					<Col xs lg="2"></Col>
				</Row>
			</Container>
			<Container
				fluid
				className={[styles.defaults, styles.background].join(" ")}
			>
				<Row className="justify-content-md-center">
					<Col sm className={styles.contentGrid}></Col>
					<Col sm>
						<p>
							<span className={styles.primaryText}>{t("welcome")}</span>
							<span className={styles.secondaryText}>
								{" "}
								-{" "}
								{t("steps").replace("X", step.current).replace("Y", step.total)}
							</span>
						</p>
						<StepsPill step={step}></StepsPill>
						<main className={styles.formContainer}>
							<Formik
								enableReinitialize={true}
								validationSchema={schema}
								onSubmit={handleSubmit}
								initialValues={form}
							>
								{({
									handleSubmit,
									handleChange,
									setFieldValue,
									values,
									touched,
									dirty,
									isValid,
									errors,
								}) => (
									<Form noValidate onSubmit={handleSubmit}>
										{products
											.filter(
												(x) =>
													queryParams.get("productId") === undefined ||
													queryParams.get("productId") === null ||
													queryParams.get("productId") === "" ||
													queryParams.get("productId") == x.id
											)
											.map(
												({ id, name, translationKey, priceBrl, priceUsd }) => (
													<Row
														key={id}
														className={`${styles.product} ${touched.productId && errors.productId
															? styles.productIsRequired
															: ""
															}`}
														onClick={() => {
															setFieldValue("productId", id);
															setStep((prevState) => ({
																...prevState,
																current: 3,
															}));
														}}
													>
														<Col className="text-center p-2">
															<p
																className={
																	queryParams.get("productId") === undefined ||
																		queryParams.get("productId") === null ||
																		queryParams.get("productId") === ""
																		? styles.productLabel
																		: ""
																}
															>
																{t(`products.${translationKey}`) !==
																	`products.${translationKey}`
																	? t(`products.${translationKey}`)
																	: name}
															</p>
															<p
																className={
																	queryParams.get("productId") === undefined ||
																		queryParams.get("productId") === null ||
																		queryParams.get("productId") === ""
																		? styles.productLabel
																		: ""
																}
															>
																{customer?.countryId === 1
																	? priceBrl.toLocaleString("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	})
																	: priceUsd.toLocaleString("en-US", {
																		style: "currency",
																		currency: "USD",
																	})}
															</p>
														</Col>
														{queryParams.get("productId") === undefined ||
															queryParams.get("productId") === null ||
															queryParams.get("productId") === "" ? (
															<Col
																sm="2"
																className="text-center p-2 position-relative"
															>
																<Form.Check>
																	<Form.Check.Input
																		className="position-absolute top-50 start-50 translate-middle"
																		type="radio"
																		id="product"
																		name="productId"
																		value={id}
																		checked={id == values.productId}
																		onChange={(e) => {
																			e.target.value = id;
																			setStep((prevState) => ({
																				...prevState,
																				current: 3,
																			}));
																			handleChange(e);
																		}}
																	/>
																</Form.Check>
															</Col>
														) : null}
													</Row>
												)
											)}

										{values.productId !== undefined &&
											values.productId !== null &&
											values.productId !== "" &&
											(values.paymentGatewayType !== PaymentGatewayType.PayPal ||
												(values.paymentGatewayType ===
													PaymentGatewayType.PayPal &&
													!values.sending)) ? (
											<>
												<p className="fw-bold pt-4">{t("labelPaymenType")}</p>
												{paymentGateways.map(({ id, name, type }) => (
													<Row key={id} className="mt-4 mb-4">
														<Col className="d-grid gap-2">
															{type !== PaymentGatewayType.PayPal ? (
																<Button
																	variant="primary"
																	fluid
																	className={styles.btnPrimary}
																	onClick={() => {
																		setFieldValue("paymentGatewayId", id);
																		setFieldValue("paymentGatewayType", type);
																	}}
																>
																	{t(`paymentGateways.${type}`) !== ""
																		? t(`paymentGateways.${type}`)
																		: name}
																</Button>
															) : null}
															{type === PaymentGatewayType.PayPal &&
																products.find(
																	(x) =>
																		x.id == values.productId &&
																		x.isRecurrence &&
																		x.payPalProductId !== null &&
																		x.payPalProductId !== ""
																) !== undefined ? (
																	<></>
																// <PayPalScriptProvider
																// 	options={{
																// 		"client-id": PayPalClientId,
																// 		components: "buttons",
																// 		intent: "subscription",
																// 		vault: true,
																// 	}}
																// >
																// 	<ButtonWrapper
																// 		type="subscription"
																// 		onApprove={(data, actions) => {
																// 			values.paymentGatewayId = id;
																// 			setFieldValue("paymentGatewayId", id);
																// 			setFieldValue("paymentGatewayType", type);
																// 			payPalOnAprove(values, data, actions);
																// 		}}
																// 		payPalProductId={
																// 			customer.countryId === 1
																// 				? products.find(
																// 					(x) => x.id == values.productId
																// 				)?.payPalBrlProductId
																// 				: products.find(
																// 					(x) => x.id == values.productId
																// 				)?.payPalUsdProductId
																// 		}
																// 	/>
																// </PayPalScriptProvider>
															) : null}
															{type === PaymentGatewayType.PayPal &&
																products.find(
																	(x) =>
																		x.id == values.productId && !x.isRecurrence
																) !== undefined ? (null
																// <BtnPayPalOrder
																// 	onApprove={(data, actions) => {
																// 		values.paymentGatewayId = id;
																// 		setFieldValue("paymentGatewayId", id);
																// 		setFieldValue("paymentGatewayType", type);
																// 		payPalOnAprove(values, data, actions);
																// 	}}
																// 	currencyCode={
																// 		customer.countryId === 1 ? "BRL" : "USD"
																// 	}
																// 	price={
																// 		customer.countryId === 1
																// 			? products.find(
																// 				(x) => x.id == values.productId
																// 			)?.priceBrl
																// 			: products.find(
																// 				(x) => x.id == values.productId
																// 			)?.priceUsd
																// 	}
																// />
															) : null}
														</Col>
													</Row>
												))}
											</>
										) : null}

										{values.paymentGatewayType === PaymentGatewayType.PayPal &&
											values.sending ? (
											<Alert variant="info">
												{t("messages.info.postPayPalPaymentSuccess")}
											</Alert>
										) : null}

										{values.paymentGatewayType &&
											values.paymentGatewayType !== PaymentGatewayType.PayPal ? (
											<>
												<p className="mt-4 fw-bold">{t("labelPaymentData")}</p>
												<Form.Group className="mb-3">
													<Form.Label>
														<span className="text-danger">*</span>{" "}
														{t("creditCard.labelHolderName")}
													</Form.Label>
													<Form.Control
														type="text"
														maxLength={25}
														placeholder={t("creditCard.placeHolderHolderName")}
														name="creditCardHolderName"
														value={values.creditCardHolderName}
														onChange={(e) => {
															e.target.value = removeDiacritics(
																e.target.value.toUpperCase()
															);
															handleChange(e);
														}}
														isValid={
															touched.creditCardHolderName &&
															!errors.creditCardHolderName
														}
														isInvalid={
															touched.creditCardHolderName &&
															errors.creditCardHolderName
														}
													/>
												</Form.Group>

												<Form.Group className="mb-3">
													<Form.Label>
														<span className="text-danger">*</span>{" "}
														{t("creditCard.labelNumber")}
													</Form.Label>
													<InputGroup className="mb-3">
														{/* 
														<Form.Select
															className="col-4"
															name="creditCardBrandId"
															value={values.creditCardBrandId}
															onChange={handleChange}
															isValid={
																touched.creditCardBrandId &&
																!errors.creditCardBrandId
															}
															isInvalid={
																touched.creditCardBrandId &&
																errors.creditCardBrandId
															}
														>
															<option value="">{t("creditCard.labelSelectBrand")}</option>
															{brands.map(({id, name, image}) => (
																<option value={id}>
																	{name}
																</option>
															))}
														</Form.Select>
														*/}
														<InputGroup.Text
															className={`p-0 ${styles.creditCardBrandLogo} ${errors.creditCardBrandId
																? "is-valid text-danger"
																: ""
																}`}
														>
															{values.creditCardBrandId !== undefined &&
																values.creditCardBrandId !== "" ? (
																<Image
																	fluid
																	thumbnail
																	src={
																		brands.find(
																			(x) => x.id === values.creditCardBrandId
																		).image
																	}
																	alt={
																		brands.find(
																			(x) => x.id === values.creditCardBrandId
																		).name
																	}
																/>
															) : (
																<Image
																	fluid
																	thumbnail
																	src={CreditCardIcon}
																	alt="@"
																/>
															)}
														</InputGroup.Text>
														<InputMask
															type="text"
															name="creditCardNumber"
															value={values.creditCardNumber}
															onChange={(e) => {
																if (
																	removeNonNumeric(e.target.value).length === 16
																) {
																	let brandId = creditCardType(e.target.value);
																	setFieldValue(
																		"creditCardBrandId",
																		brandId !== undefined ? brandId : ""
																	);
																} else {
																	setFieldValue("creditCardBrandId", "");
																}
																handleChange(e);
															}}
															className={`form-control 
														${touched.creditCardNumber && !errors.creditCardNumber ? "is-valid" : ""}
														${touched.creditCardNumber && errors.creditCardNumber ? "is-invalid" : ""}`}
															mask="9999 9999 9999 9999"
														/>
													</InputGroup>
												</Form.Group>

												<Row>
													<Col sm>
														<Form.Group className="mb-3">
															<Form.Label>
																<span className="text-danger">*</span>{" "}
																{t("creditCard.labelGoodThru")}
															</Form.Label>
															<InputMask
																type="tel"
																name="creditCardGoodThru"
																value={values.creditCardGoodThru}
																onChange={handleChange}
																isValid={
																	touched.creditCardGoodThru &&
																	!errors.creditCardGoodThru
																}
																isInvalid={
																	touched.creditCardGoodThru &&
																	errors.creditCardGoodThru
																}
																className={`form-control 
														${touched.creditCardGoodThru && !errors.creditCardGoodThru ? "is-valid" : ""}
														${touched.creditCardGoodThru && errors.creditCardGoodThru ? "is-invalid" : ""}`}
																mask="99/99"
															/>
														</Form.Group>
													</Col>
													<Col sm>
														<Form.Group className="mb-3">
															<Form.Label>
																<span className="text-danger">*</span>{" "}
																{t("creditCard.labelCvv")}
															</Form.Label>
															<InputMask
																type="text"
																name="creditCardCvv"
																value={values.creditCardCvv}
																onChange={handleChange}
																isValid={
																	touched.creditCardCvv && !errors.creditCardCvv
																}
																isInvalid={
																	touched.creditCardCvv && errors.creditCardCvv
																}
																className={`form-control 
														${touched.creditCardCvv && !errors.creditCardCvv ? "is-valid" : ""}
														${touched.creditCardCvv && errors.creditCardCvv ? "is-invalid" : ""}`}
																mask="999"
															/>
														</Form.Group>
													</Col>
												</Row>

												<hr></hr>
												<p className="fw-bold">{t("coupon.title")}</p>
												<Row>
													<Col>
														<Form.Group>
															<Form.Control
																type="text"
																name="discountCoupon"
																maxLength={20}
																value={values.discountCoupon}
																onChange={(e) => {
																	setFieldValue("discountCouponIsInvalid", {
																		touched: false,
																		invalid: false,
																	});
																	handleChange(e);
																}}
																placeholder={t("coupon.placeHolder")}
																isInvalid={
																	values.discountCouponIsInvalid.touched &&
																	!values.discountCouponIsInvalid.invalid
																}
															/>
														</Form.Group>
													</Col>
													<Col sm="4" className="d-grid gap-2">
														<Button
															className="gtm_form_etapa3_coupon"
															variant={
																values.discountCoupon !== undefined &&
																	values.discountCoupon !== ""
																	? "primary"
																	: "secondary"
															}
															disabled={
																values.discountCoupon === undefined ||
																values.discountCoupon === ""
															}
															size="sm"
															onClick={() =>
																setFieldValue("discountCouponIsInvalid", {
																	touched: true,
																	invalid: validateCoupon(
																		values.productId,
																		values.discountCoupon
																	),
																})
															}
														>
															{t("coupon.btnSubmit")}
														</Button>
													</Col>
												</Row>

												<Row className="mt-4">
													<Col className="d-grid gap-2">
														{dirty && isValid ? (
															<Button
																type="submit"
																variant="primary"
																className={`gtm_form_etapa3_credito ${styles.btnPrimary} ${styles.btnRadiusRounded}`}
																disabled={values.sending}
															>
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																	className={
																		!values.sending
																			? "visually-hidden spinner-right-margin"
																			: "spinner-right-margin"
																	}
																/>
																{t("btnSubmit").toLocaleUpperCase()}
															</Button>
														) : null}
													</Col>
												</Row>
											</>
										) : null}
									</Form>
								)}
							</Formik>
						</main>
					</Col>
					<Col sm className={styles.contentGrid}></Col>
				</Row>
				<ChangeLanguages />
			</Container>
		</>
	);
}

function BtnPayPalOrder(props) {
	useEffect(() => {
		console.log(props.currencyCode, props.price);
	}, [props.price]);

	const gtmForm = () => {
		console.log("gtm_form_etapa3_paypay")
	}

	return (
		<PayPalScriptProvider
			options={{ "client-id": PayPalClientId, currency: props.currencyCode }}
		>
			<div className="gtm_form_etapa3_paypay">
				<PayPalButtons
					className="gtm_form_etapa3_paypay"
					onClick={gtmForm}
					style={{
						shape: "rect",
						color: "blue",
						layout: "horizontal",
					}}
					createOrder={(data, actions) => {
						return actions.order.create({
							purchase_units: [
								{
									amount: {
										value: props.price,
									},
								},
							],
						});
					}}
					onApprove={(data, actions) => props.onApprove(data, actions)}
				/>
			</div>
		</PayPalScriptProvider>
	);
}

const ButtonWrapper = ({ payPalProductId, onApprove }) => {
	const [{ options }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
		console.log(payPalProductId);
		dispatch({
			type: "resetOptions",
			value: {
				...options,
				intent: "subscription",
			},
		});
	}, [payPalProductId]);
	const gtmForm = () => {
		console.log("gtm_form_etapa3_paypay")
	}

	return (
		<PayPalButtons
			className="gtm_form_etapa3_paypay"
			onClick={gtmForm}
			style={{
				shape: "rect",
				color: "blue",
				layout: "horizontal",
				label: "subscribe",
			}}
			createSubscription={(data, actions) => {
				return actions.subscription
					.create({
						plan_id: payPalProductId,
					})
					.then((orderId) => {
						// Your code here after create the order
						return orderId;
					});
			}}
			onApprove={(data, actions) => onApprove(data, actions)}
		/>
	);
};