import React, { useState } from 'react';
import { Form, FloatingLabel, InputGroup, Button, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2';
import { decode as Base64Decode } from 'base-64'
import LogoAzul from "../images/logo-azul.png";
import BackImage from "../images/webdoor.png";
import "../styles/change-password.css";
import apijaw from "../services/api-jaw";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useDocumentTitle from "../components/useDocumentTitle";
import ChangeLanguages from '../components/changeLanguage'
import { AiOutlineMail } from "react-icons/ai";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import PasswordStrength from "../components/PasswordStrengthIndicator.jsx"

export default function ChangePasswordWithPhone(props) {
    const { id } = useParams();
    const { t, i18n } = useTranslation('change-password');
    const [exibirMensagem, setExibirMensagem] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
    const [form, setForm] = useState({ "confirmationCode": "", "newPassword": "", "confirmPassword": "" });
    const [messageStrong, setMessageStrong] = useState("");

    useDocumentTitle(t('title'))

    const [reSentForm, setReSentForm] = useState({
        reSent: false
    });

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const validateForm = () => {
        return form.confirmationCode.length > 5 && form.newPassword.length > 7 && form.confirmPassword.length > 7 && (form.newPassword == form.confirmPassword);
    }

    const handleDecodeCellphoneBase64 = (e) => {
        const cellphone = Base64Decode(getQueryVariable("phonenumber"));
        return cellphone
    }

    const handleToken = (e) => {
        const token = Base64Decode(getQueryVariable("token"));
        return token
    }

    const handleCustomerId = (e) => {
        const customerId = Base64Decode(getQueryVariable("Id"));
        return customerId
    }

    const getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    const handleChange = (e) => {
        setExibirMensagem(false)
        const name = e.target.name;
        const value = e.target.value;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const getActiveColorConfirmPassword = (type) => {
        if (type !== form.confirmPassword) return "#FF0054";
    };

    const handleSubmit = () => {

        apijaw.post("/Customer/ChangePassword/ByPhone", {
            confirmationCode: form.confirmationCode,
            token: handleToken(),
            cellphone: handleDecodeCellphoneBase64(),
            password: form.confirmPassword,
            newPassword: form.newPassword,
        })
            .then((response) => {
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Senha alterada com sucesso.',
                    }).then(function (result) {
                        window.location.href = "/";
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: 'Não foi possivel enviar a requisição no momento, tente novamente mais tarde.',
                    });
                }
            })
            .catch((error) => {
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: 'Não foi possivel enviar a requisição no momento, tente novamente mais tarde.'
                    });
                }
            });
    };

    const handleresendPhoneConfirmation = (values) => {
        setReSentForm({ reSent: true });
        apijaw
            .patch("/Customer/ResendPhoneConfirmation", {
                customerId: handleCustomerId(),
            },
                {
                    headers: {
                        'Accept-Language': i18n.language
                    }
                })
            .then((response) => {
                setReSentForm({ reSent: false });
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: t('verifyMessage'),
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: t('reSendEmailFail'),
                    });
                }
            })
            .catch((error) => {
                setReSentForm({ sending: false });
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('reSendEmailFail')
                    });
                }
            });
    };

    return (
        <>
            <div className="login-container">
                <div
                    className="section-webdoor"
                    style={{
                        backgroundImage: `url(${BackImage})`,
                    }}
                >
                    <div className="content-change-password-with-email">
                        <Form>
                            <p style={{ fontSize: "1.4em" }} className="text-center">
                                <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                            </p>

                            <div className="icons-form-registration">
                                <FloatingLabel
                                    controlId="flNewPassword"
                                    label={t('code')}
                                >
                                    <Form.Control
                                        autoFocus
                                        maxLength="10"
                                        type="text"
                                        name="confirmationCode"
                                        required
                                        value={form.confirmationCode}
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                                <InputGroup.Text>
                                    <AiOutlineMail />
                                </InputGroup.Text>
                            </div>

                            <div>
                                <div className="icons-form-registration">
                                    <FloatingLabel
                                        controlId="flNewPassword"
                                        label={t('newPasswordField')}
                                    >
                                        <Form.Control
                                            maxLength="30"
                                            type={hidePassword ? "password" : "text"}
                                            name="newPassword"
                                            required
                                            value={form.newPassword}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                    <InputGroup.Text className="change-password-shown" id="basic-addon1"
                                        onClick={() => {
                                            setHidePassword(!hidePassword);
                                        }} >
                                        {!hidePassword ? <BsEye /> : <BsEyeSlash />}
                                    </InputGroup.Text>
                                </div>
                                <PasswordStrength password={form.newPassword} />
                            </div>

                            <div>
                                <div className="icons-form-registration">
                                    <FloatingLabel
                                        controlId="flConfirmPassword"
                                        label={t('confirmPasswordField')}
                                    >
                                        <Form.Control
                                            maxLength="30"
                                            type={hideConfirmPassword ? "password" : "text"}
                                            name="confirmPassword"
                                            required
                                            value={form.confirmPassword}
                                            onChange={handleChange}
                                        />
                                    </FloatingLabel>
                                    <InputGroup.Text className="change-password-shown" id="basic-addon1"
                                        onClick={() => {
                                            setHideConfirmPassword(!hideConfirmPassword);
                                        }} >
                                        {!hideConfirmPassword ? <BsEye /> : <BsEyeSlash />}
                                    </InputGroup.Text>
                                </div>

                                <div>
                                    {form.confirmPassword !== form.newPassword && form.confirmPassword.length > 3 ? (
                                        <p className="message" style={{ color: getActiveColorConfirmPassword(messageStrong) }}>
                                            {t('matchPassword')}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="btn-change-password-with-celphone">
                                <Button
                                    className="d-grid gap-2 button"
                                    style={{ backgroundColor: "#26AAE2" }}
                                    size="lg"
                                    onClick={handleSubmit}
                                    disabled={!validateForm()}
                                >
                                    {t('sentBtn')}
                                </Button>

                                <Button
                                    onClick={handleresendPhoneConfirmation}
                                    className="d-grid gap-2 button gtm_reenvio_codigo"
                                    style={{ backgroundColor: "#26AAE2" }}
                                    size="sm"
                                >
                                    <span>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className={!reSentForm.reSent ? 'visually-hidden spinner-right-margin' : 'spinner-right-margin'}
                                        />
                                        {t('resendConfirmationCode')}
                                    </span>
                                </Button>
                            </div>
                        </Form>
                        <div className='white-dropdown-text'>
                            <ChangeLanguages />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}