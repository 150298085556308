import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Alert, Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Countries } from '../../consts';
import api from '../../services/api';
import "../../styles/login.css";
import "../../styles/pageForm.css";
import "./NeoForm.css";

export default function NeoForm(props) {
    const { t, i18n } = useTranslation('login-page');
    const [form, setForm] = useState({ contryCodePhone: Countries[0].code, email: "", password: "" });
    const [hidePassword, setHidePassword] = useState(true);
    const [exibirMensagem, setExibirMensagem] = useState(false);
    const [alerta, setAlerta] = useState({ mensagem: "", variant: "info" });
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const validateForm = () => {
        console.log(form);
        return String(form.username) && form?.phoneNumber?.replace(/\D/g, '')?.length >= 9 && form?.idp;
    }

    const handleSubmit = () => {
        setLoadingSubmit(true);

        api.post('/auth/external-provider/login', {
            phoneNumber: `${form.contryCodePhone}${form.phoneNumber}`,
            provider: 'NEO',
            metadata: {
                username: form.username,
                password: form.password,
                idp: form.idp
            }
        }).then((response) => { props.onSubmit(response) })
        .catch((error) => {
            console.log(error);
            setExibirMensagem(true);
            if (error.response.data.message === 'Invalid coupon') {
                setAlerta({ mensagem: t('invalidCouponMessage'), variant: "danger" });
            } else {
                setAlerta({ mensagem: t('couldntSendRequestMessage'), variant: "danger" });
            }
        })
        .finally(() => { setLoadingSubmit(false) });
    };

    const handleChange = (e) => {
        setExibirMensagem(false)
        const name = e.target.name;
        if (form.contryCodePhone === Countries[0].code && name === 'phoneNumber')
            e.target.value = phoneMask(e.target.value);

        const value = e.target.value;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }


    return (<>
        {exibirMensagem ? <Alerta mensagem={alerta.mensagem} variant={alerta.variant} /> : null}

        <Form.Label>
            <p>{t('neoAuthLabel')}</p>
        </Form.Label>

        <Form className='otpForm'>
            <InputGroup className='neoPhoneNumberInputGroup'>
                <FloatingLabel
                    controlId="flContryCodePhone"
                    label={t('contryCode')}
                    className='countryCode'>
                    <Form.Select
                        name="contryCodePhone"
                        value={form.contryCodePhone}
                        readOnly={form.phoneSent}
                        onChange={handleChange}
                    >
                        {
                            Countries.map(({ id, name, code }) => (
                                <option key={id} value={code}>+{code} {name}</option>
                            ))
                        }
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                    controlId="flPhone"
                    label={t('phoneNumberLabel')}
                    className='phoneNumber'
                >
                    <Form.Control
                        autoFocus
                        type="tel"
                        name="phoneNumber"
                        value={form.phoneNumber}
                        readOnly={form.phoneSent}
                        onChange={handleChange}
                    />
                </FloatingLabel>
            </InputGroup>

            <FloatingLabel
                controlId="flUsername"
                label={t('usernameLabel')}
            >
                <Form.Control
                            autoFocus
                            type="text"
                            name="username"
                            value={form.username}
                            onChange={handleChange}
                        />
            </FloatingLabel>

            <FloatingLabel
                    controlId="flPass"
                    label={t('passwordLabel')}
                >
                    <Form.Control                    
                        type={hidePassword ? "password" : "text"}
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <FloatingLabel
                controlId="flIdp"
                label={t('idpLabel')}
            >
                <Form.Control
                            autoFocus
                            type="text"
                            name="idp"
                            value={form.idp}
                            onChange={handleChange}
                        />
            </FloatingLabel>

            <Button
                style={{ backgroundColor: "#26AAE2" }}
                size="lg"
                onClick={() => handleSubmit()}
                disabled={!validateForm() || loadingSubmit}
            >
                {loadingSubmit ? <span><FontAwesomeIcon spin={true} icon={faSpinner} /></span> : t('buttonLabel')}
            </Button>
        </Form>
    </>)
}
const Alerta = (props) => (
    <Alert variant={props.variant} className="text-center">
        <div dangerouslySetInnerHTML={{ __html: props.mensagem }} />
    </Alert>
)

const numberMask = (value) => {
    if (!value) return '';
    return value.replace(/\D/g, "");
}

const phoneMask = (value) => {
    if (!value) return '';
    return numberMask(value)
        .replace(/^(\d{2})(\d)/g, "($1) $2")
        .replace(/(\d)(\d{4})$/, "$1-$2");
}
