import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2';
import { Formik } from 'formik';
import * as yup from 'yup';
import useDocumentTitle from "../components/useDocumentTitle";
import apijaw from "../services/api-jaw";
import "../styles/pageForm.css";
import "../styles/nuuvemIntegration.css";
import { CompanyId } from "../consts";
import LogoAzul from "../images/logo-azul.png";
import BackImage from "../images/webdoor.png";

export default function NuuvemIntegration(props) {
    const { t, i18n } = useTranslation('nuuvem-Integration');
    useDocumentTitle(t('Nuuvem Integration'))

    const { email, activationToken } = useParams();

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })

    const [form, setForm] = useState({
        email: email,
        companyId: CompanyId,
        activationToken: activationToken,
        name: undefined,
        sending: false
    });

    const handleSubmitThis = async (values) => {
        setForm({ sending: true });
        apijaw
            .post("/Customer/NuuvemIntegrationActivation", values,
                {
                    headers: {
                        'Accept-Language': i18n.language
                    }
                })
            .then((response) => {
                setForm({ sending: false });
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: t('successMessageIntegratedUser'),
                    }).then(function () {
                        window.location.href = "https://jaw.games/nuuvem-Integrated";
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: t('couldntSendRequestMessage'),
                    });
                }
            })
            .catch((error) => {
                setForm({ sending: false });
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    let alreadyActiveAccount = error.response.data.errors.map(e => e.errorCode).join();

                    if (alreadyActiveAccount === "AccountAlreadyActive") {
                        return swalWithBootstrapButtons.fire({
                            text: t('generatePassword'),
                            icon: 'info',
                            showCancelButton: true,
                            cancelButtonText: t('no'),
                            confirmButtonText: t('yes'),
                            reverseButtons: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                apijaw
                                    .post("/Customer/NuuvemIntegrationGenerateNewPassword", values,
                                        {
                                            headers: {
                                                'Accept-Language': i18n.language
                                            }
                                        })
                                    .then((response) => {
                                        setForm({ sending: false });
                                        if (response.data.success === true) {
                                            Swal.fire({
                                                icon: 'success',
                                                text: t('redefinePasswordSuccessMessage'),
                                            }).then(function () {
                                                window.location.href = "https://jaw.games/nuuvem-Integrated";
                                            });
                                        } else {
                                            Swal.fire({
                                                icon: 'info',
                                                text: t('couldntSendRequestMessage'),
                                            });
                                        }
                                    })

                            } else if (
                                result.dismiss === Swal.DismissReason.cancel
                            ) {
                                window.location.href = "https://jaw.games/nuuvem-Integrated";
                            }
                        })
                    }
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('couldntSendRequestMessage')
                    });
                }
            });
    };

    const isValidName = (name) => {
        if (name == undefined)
            return false;
        const regex = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$/;
        let normalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let result = regex.test(normalized);
        return result;
    }

    const schema = yup.object().shape({
        name: yup.string().trim().test('test-name', 'Informe o nome completo.', (value, context) => isValidName(value)),
    });

    return (
        <>
            <div className="login-container">
                <div
                    className="section-webdoor"
                    style={{
                        backgroundImage: `url(${BackImage})`,
                    }}
                >
                    <div className="content">

                        <Formik
                            enableReinitialize={true}
                            validationSchema={schema}
                            onSubmit={handleSubmitThis}
                            initialValues={form}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <p style={{ fontSize: "1.4em" }} className="text-center">
                                        <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                                    </p>
                                    <Form.Group className="mb-3 welcome-form-label" controlId="flToken">
                                        <Form.Label>
                                            <p className="welcome-bold-paragraph">{t('toggleWelcome')}</p>
                                            <p>{t('toggleLabelIntegratedUserName')}</p>
                                        </Form.Label>
                                        <Form.Control
                                            autoFocus
                                            type="text"
                                            name="name"
                                            placeholder={t('toggleName')}
                                            maxLength={100}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={errors.name}
                                        />
                                    </Form.Group>

                                    <div className="btn-confirm-resend">
                                        <Button
                                            type="submit"
                                            className="d-grid gap-2 button"
                                            style={{ backgroundColor: "#26AAE2" }}
                                            size="lg"
                                            disabled={values?.name == undefined || (!isValid || (form.sending === true))}
                                        >
                                            <span>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className={!form.sending ? 'visually-hidden spinner-right-margin' : 'spinner-right-margin'}
                                                />
                                                {t('access')}
                                            </span>
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}