import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, FloatingLabel, Button, InputGroup } from "react-bootstrap";
import Swal from 'sweetalert2';
import LogoAzul from "../images/logo-azul.png";
import BackImage from "../images/webdoor.png";
import "../styles/recovery-password.css";
import apijaw from "../services/api-jaw";
import { CompanyId } from '../consts';
import { AiOutlineMail } from "react-icons/ai";
import { BsFillPhoneFill } from "react-icons/bs";

class RecoveryPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateForm() {
    const validateCellphoneOrEmail = Object.values(this.state.email).filter(Number);

    return (String(this.state.email).includes('@' && '.') || (validateCellphoneOrEmail.length >= 8 && validateCellphoneOrEmail.length <= 14));
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  handleSubmit = (t) => {
    const isCustomerCelphone = new Boolean(!isNaN(this.state.email))
    let emailOrCellphone = `${isCustomerCelphone == true ? 55 + this.state.email : this.state.email}`

    apijaw
      .post("/Customer/ForgotPassword", {
        CompanyId: CompanyId,
        Email: emailOrCellphone,
      },
        {
          headers: {
            'Accept-Language': t('language')
          }
        })
      .then((response) => {

        if (response.data.success === true && isCustomerCelphone == false) {
          Swal.fire({
            icon: 'success',
            text: t('successEmailMessage'),
          }).then(function (result) {
            window.location.href = "/";
          });
        } else if (response.data.success === true && isCustomerCelphone == true) {
          Swal.fire({
            icon: 'success',
            text: t('cellphoneSuccessMessage'),
          }).then(function (result) {
            window.location.href = "change-password-with-phone"
              + "?phonenumber=" + response.data.data.cellphone + "&token=" + response.data.data.token
              + "&Id=" + response.data.data.customerId;
          });
        } else {
          Swal.fire({
            icon: 'info',
            text: t('couldntSendRequestMessage'),
          });
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors != undefined) {
          let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
          Swal.fire({
            icon: 'error',
            html: message
          });
        }
        else {
          Swal.fire({
            icon: 'error',
            text: t('requestFaliureMessage')
          });
        }
      });
  };

  render() {
    const { t } = this.props;

    document.title = "Jaw Games | " + t('title');

    return (
      <>
        <div className="login-container">
          <div
            className="section-webdoor"
            style={{
              backgroundImage: `url(${BackImage})`,
            }}
          >
            <div className="content">
              <Form>
                <p style={{ fontSize: "1.4em" }} className="text-center">
                  <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                </p>

                <div className="icons-form-registration">
                  <FloatingLabel
                    controlId="flEmail"
                    label={t('emailLabel')}
                  >
                    <Form.Control
                      maxLength="50"
                      autoFocus
                      type="email"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>

                  <InputGroup.Text>
                    <AiOutlineMail /> <BsFillPhoneFill />
                  </InputGroup.Text>
                </div>

                <Button
                  className="d-grid gap-2 button only-screen-280-375"
                  style={{ backgroundColor: "#26AAE2" }}
                  size="lg"
                  onClick={() => this.handleSubmit(t)}
                  disabled={!this.validateForm()}
                >
                  {t('buttonLabel')}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('recovery-password-page')(RecoveryPassword);