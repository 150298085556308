import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecaptchaVerifier, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useRef, useState } from "react";
import { Alert, Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useDocumentTitle from "../../components/useDocumentTitle";
import { Countries } from '../../consts';
import api from '../../services/api';
import "../../styles/pageForm.css";
import "./OtpForm.css";

export default function LoginOtpForm(props) {
    const { t, i18n } = useTranslation('login-page');
    const recaptchaWrapperRef = useRef(undefined);
    let recaptchaVerify = undefined;

    useDocumentTitle("Login")
    let [searchParams, setSearchParams] = useSearchParams();

    const firebaseAuth = getAuth();
    firebaseAuth.useDeviceLanguage();

    const [formPhone, setFormPhone] = useState({
        contryCodePhone: Countries[0].code,
        phoneNumber: "",
        authCode: "",
        promoCode: "",
        phoneSent: false
    });

    const [exibirMensagem, setExibirMensagem] = useState(false);
    const [alerta, setAlerta] = useState({ mensagem: "", variant: "info" });

    const [loadingSendCode, setLoadingSendCode] = useState(false);
    const [loadingSubmitCode, setLoadingSubmitCode] = useState(false);

    const validateFormPhone = () => {
        return formPhone?.phoneNumber?.replace(/\D/g, '')?.length >= 9
            && formPhone?.authCode?.length > 0;
    }

    const handleChangeFromPhone = (e) => {
        setExibirMensagem(false)
        const name = e.target.name;
        if (formPhone.contryCodePhone === Countries[0].code && name === 'phoneNumber')
            e.target.value = phoneMask(e.target.value);

        const value = e.target.value;
        setFormPhone((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const [final, setfinal] = useState("");

    // Sent OTP
    const signin = async () => {
        if (formPhone.phoneNumber === "") return;

        setLoadingSendCode(true);
        setFormPhone({
            contryCodePhone: formPhone.contryCodePhone,
            phoneNumber: formPhone.phoneNumber,
            phoneSent: true
        });

        if (recaptchaVerify) {
            await recaptchaVerify.clear();
        }

        recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;

        recaptchaVerify = new RecaptchaVerifier(
            firebaseAuth,
            "recaptcha-container",
            { size: "invisible" }
        );
        
        recaptchaVerify.verify()
            .then((recaptchaToken) => {
                return api.post(`/auth/otp`, {
                    phone: {
                        countryCode: numberMask(formPhone.contryCodePhone),
                        phoneNumber: numberMask(formPhone.phoneNumber),
                    },
                    appVerify: recaptchaToken
                });
            })
            .then((result) => {
                setfinal(result);
                setExibirMensagem(true);
                setAlerta({ mensagem: t('checkSms'), variant: "success" });
            })
            .catch((error) => {
                console.log(error);
                setFormPhone({
                    contryCodePhone: formPhone.contryCodePhone,
                    phoneNumber: formPhone.phoneNumber,
                    phoneSent: false
                });
                setExibirMensagem(true);
                setAlerta({ mensagem: t('couldntSendRequestMessage'), variant: "danger" });
            })
            .finally(() => {
                setLoadingSendCode(false);
            });
    };

    // Validate OTP
    const ValidateOtp = () => {
        if (!formPhone.authCode) return;
        const fullNumber = `+${numberMask(formPhone.contryCodePhone + formPhone.phoneNumber)}`;
        const coupon = formPhone.promoCode === "" ? undefined : formPhone.promoCode?.toUpperCase();
        
        setLoadingSubmitCode(true);
        signInWithEmailAndPassword(firebaseAuth, `${fullNumber}@app.com`, formPhone.authCode)
            .then((result) => {
                const urlParams = new URLSearchParams(window.location.search);
                const authRedirectUrl = urlParams.get('authRedirectUrl');
                if (authRedirectUrl) {
                    window.location = `${authRedirectUrl}?phoneNumber=${result.user.phoneNumber.replace('+', '')}&token=${result.user.accessToken}`
                }

                return api.post(`/auth`, {
                    coupon,
                }, {
                    headers: {
                        'Authorization': result.user.accessToken
                    }
                })
            })
            .then((response) => { if (coupon) { setSearchParams({promo_code: coupon}); } props.onSubmit(response) })
            .catch((error) => {
                console.log(error);
                setExibirMensagem(true);
                if (error.response.data.message === 'Invalid coupon') {
                    setAlerta({ mensagem: t('invalidCouponMessage'), variant: "danger" });
                } else {
                    setAlerta({ mensagem: t('couldntSendRequestMessage'), variant: "danger" });
                }
            }).finally(() => {
                setLoadingSubmitCode(false);
            });
    };

    return (<>
        {exibirMensagem ? <Alerta mensagem={alerta.mensagem} variant={alerta.variant} /> : null}

        <Form.Label>
            <p>{t('otpAuthLabel')}</p>
        </Form.Label>

        <Form className='otpForm'>
            <div ref={recaptchaWrapperRef}></div>
            <InputGroup className='phoneNumberInputGroup'>
                <FloatingLabel
                    controlId="flContryCodePhone"
                    label={t('contryCode')}
                    className='countryCode'>
                    <Form.Select
                        name="contryCodePhone"
                        value={formPhone.contryCodePhone}
                        readOnly={formPhone.phoneSent}
                        onChange={handleChangeFromPhone}
                    >
                        {
                            Countries.map(({ id, name, code }) => (
                                <option key={id} value={code}>+{code} {name}</option>
                            ))
                        }
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                    controlId="flPhone"
                    label={t('phoneNumberLabel')}
                    className='phoneNumber'
                >
                    <Form.Control
                        autoFocus
                        type="tel"
                        name="phoneNumber"
                        value={formPhone.phoneNumber}
                        readOnly={formPhone.phoneSent}
                        onChange={handleChangeFromPhone}
                    />
                </FloatingLabel>
                <Button
                    disabled={formPhone.phoneSent || formPhone?.phoneNumber?.length < 9}
                    onClick={() => signin()}
                    className='sendCodeButton'
                >
                    {loadingSendCode ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : <FontAwesomeIcon icon={faPaperPlane} />}
                </Button>
            </InputGroup>

            <FloatingLabel
                controlId="flauthCode"
                label={t('authCodeLabel')}
            >
                <Form.Control
                    name="authCode"
                    value={formPhone.authCode}
                    onChange={handleChangeFromPhone}
                />
            </FloatingLabel>

            <FloatingLabel
                controlId="flpromoCode"
                label={t('promoCodeLabel')}
            >
                <Form.Control
                    name="promoCode"
                    required={false}
                    value={formPhone.promoCode}
                    onChange={handleChangeFromPhone}
                />
            </FloatingLabel>

            <Button
                style={{ backgroundColor: "#26AAE2" }}
                size="lg"
                onClick={() => ValidateOtp()}
                disabled={!validateFormPhone()}
            >
                {loadingSubmitCode ? <span><FontAwesomeIcon spin={true} icon={faSpinner} /></span> : t('buttonLabel')}
            </Button>
        </Form>
    </>)
}
const Alerta = (props) => (
    <Alert variant={props.variant} className="text-center">
        <div dangerouslySetInnerHTML={{ __html: props.mensagem }} />
    </Alert>
)

const numberMask = (value) => {
    if (!value) return '';
    return value.replace(/\D/g, "");
}

const phoneMask = (value) => {
    if (!value) return '';
    return numberMask(value)
        .replace(/^(\d{2})(\d)/g, "($1) $2")
        .replace(/(\d)(\d{4})$/, "$1-$2");
}
