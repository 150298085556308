import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Container, Row, Col, Button } from "react-bootstrap";
import { decode as Base64Decode } from 'base-64';
import useDocumentTitle from "../components/useDocumentTitle";

import apijaw from "../services/api-jaw";

import '../styles/term-acceptance.css';
import LogoAzul from "../images/logo-azul.png";

export default function TermAcceptance(props) {
    const { id } = useParams();
    const { t, i18n } = useTranslation('term-acceptance-page');

    useDocumentTitle(t('title'))

    useEffect(() => {
        getTermByAcceptanceId()
            .then((response) => {
                if(response.data?.data != undefined){
                    setTermHtml(Base64Decode(response.data.data.termTranslation.termHtml));
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('couldntLoadTerm'),
                    }).then(function (result) {
                        window.location.href = "/";
                    });
                }
            })
            .catch((error) => {
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    }).then(function (result) {
                        window.location.href = "/";
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('couldntLoadTerm'),
                    }).then(function (result) {
                        window.location.href = "/";
                    });
                }
            });
    }, []);

    const [termHtml, setTermHtml] = useState("");

    //#region Methods

    const handleSubmit = () => {
        apijaw
            .post("/Term/Accept", {
                termAcceptanceId: id
            },
            {
                headers: {
					'Accept-Language': i18n.language
				}
            })
            .then((response) => {
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: t('successMessage'),
                    }).then(function (result) {
                        window.location.href = "/";
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: t('couldntSendRequestMessage'),
                    });
                }
            })
            .catch((error) => {
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('requestFaliureMessage')
                    });
                }
            });
    };

    const getTermByAcceptanceId = () => {
        return apijaw
            .get("/Term/Acceptance/" + id,{
                headers: {
					'Accept-Language': i18n.language
				}
            });
    }

    //#endregion

    return (
        <div className="bg-container">
            <Container>
                <Row>
                    <Col className="mb-3 text-center">
                        <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="mb-3 text-center max-window-heigth" dangerouslySetInnerHTML={{ __html: termHtml }}>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="text-center">
                        <Button
                            variant="primary"
                            className="btn-round"
                            onClick={handleSubmit}>
                            {t('buttonLabel')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}