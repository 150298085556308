import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Alert, Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CompanyId } from '../../consts';
import apijaw from '../../services/api-jaw';
import "../../styles/login.css";
import "../../styles/pageForm.css";
import "./PasswordForm.css";

export default function LoginPasswordForm(props) {
    const { t, i18n } = useTranslation('login-page');
    const [form, setForm] = useState({ "email": "", "password": "" });
    const [hidePassword, setHidePassword] = useState(true);
    const [exibirMensagem, setExibirMensagem] = useState(false);
    const [alerta, setAlerta] = useState({ mensagem: "", variant: "info" });
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const validateForm = () => {
        console.log(form);
        const checkIfCellphone = Object.values(form.email).filter(Number);

        return (String(form.email).includes('@' && '.') || (checkIfCellphone.length >= 8 && checkIfCellphone.length <= 14)) && form.password.length > 5;
    }

    const handleChange = (e) => {
        setExibirMensagem(false)
        const name = e.target.name;
        const value = e.target.value;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleSubmit = () => {
        const isPhoneNumber = new Boolean(!isNaN(form.email));
        setLoadingSubmit(true);

        apijaw.post("/Customer/Auth/CredentialsAndPassword", {
            companyId: CompanyId,
            EmailOrCellphone: `${isPhoneNumber == true ? 55 + form.email : form.email}`,
            Password: form.password,
        },
            {
                headers: {
                    'Accept-Language': i18n.language
                }
            })
            .then((response) => {
                if (response.data.data?.blacknutToken != null) {
                    let objSend = JSON.stringify({
                        message: "telecall-login",
                        payload: response.data.data.blacknutToken,
                    });
                    window.top.postMessage(objSend, "*");
                    console.log(response.data.data);
                }
            }).catch((error) => {
                if (error.response?.data?.errors != undefined) {
                    setExibirMensagem(true);
                    if (error.response.data.errors.find(x => x.errorCode == "SubscriptionNotPerformed") != undefined) {
                        setAlerta({ mensagem: t('errors.subscriptionNotPerformed').replace("#CustomerId", error.response.data.data.customerId), variant: "danger" });
                    }
                    else if (error.response.data.errors.find(x => x.errorCode == "EmailNotConfirmed") != undefined) {
                        setAlerta({ mensagem: t('errors.emailNotConfirmed').replace("#CustomerId", error.response.data.data.customerId), variant: "danger" });
                    }
                    else {
                        setAlerta({ mensagem: error.response.data.errors[0].message, variant: "danger" });
                    }
                } else {
                    setExibirMensagem(true);
                    setAlerta({ mensagem: t('couldntSendRequestMessage'), variant: "danger" });
                }
            }).finally(() => {
                setLoadingSubmit(false);
            });
    };

    return (<>
        {exibirMensagem ? <Alerta mensagem={alerta.mensagem} variant={alerta.variant} /> : null}

        <Form.Label>
            <p>{t('otpAuthLabel')}</p>
        </Form.Label>

        <Form className='passwordForm'>
            <InputGroup className='phoneNumberInputGroup'>
                <FloatingLabel
                    controlId="flEmail"
                    label={t('emailLabel')}
                    style={{ width: '100%' }}
                >
                    <Form.Control
                        autoFocus
                        type="text"
                        name="email"
                        value={form.email}
                        onChange={handleChange}
                    />
                </FloatingLabel>
            </InputGroup>

            <FloatingLabel
                controlId="flPass"
                label={t('passwordLabel')}
            >
                <Form.Control                    
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                />
            </FloatingLabel>

            <Button
                style={{ backgroundColor: "#26AAE2" }}
                size="lg"
                onClick={handleSubmit}
                disabled={!validateForm() || loadingSubmit}
            >
                {loadingSubmit ? <span><FontAwesomeIcon spin={true} icon={faSpinner} /></span> : t('buttonLabel')}
            </Button>
        </Form>
    </>)
}
const Alerta = (props) => (
    <Alert variant={props.variant} className="text-center">
        <div dangerouslySetInnerHTML={{ __html: props.mensagem }} />
    </Alert>
)

const numberMask = (value) => {
    if (!value) return '';
    return value.replace(/\D/g, "");
}