import React, { useState, useEffect } from 'react';
import {Row, Col} from 'react-bootstrap';
import styles from './stepsPill.module.css';

export default function StepsPill(props){
    const buildStepsArray = () => {
        let array = [];
        for(let i = 1; i <= props.step.total; i++){
            array.push(i);
        }
        return array;
    }

    const [steps, setSteps] = useState(buildStepsArray());


    return (
        <Row>
            {steps.map(i => (
                <Col>
                    <div className={[styles.pill, (i <= props.step.current ? styles.pillActive : null) ].filter(s => s != null).join(" ")}></div>
                </Col>
            ))}
        </Row>
    );
}