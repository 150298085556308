import React from "react";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Container, Card } from "react-bootstrap";
import useDocumentTitle from "../components/useDocumentTitle";

import '../styles/payment-issues.css';
import { UrlPortalJaw } from '../consts';

export default function PaymentIssues(props) {
    const { id, errorcode } = useParams();
    const { t } = useTranslation('payment-issues-page');
    const portalLink = '<a href="'+UrlPortalJaw+'">'+UrlPortalJaw.replace('https://', '').replace('http://', '')+'</a>';

    useDocumentTitle(t('title'))
    
    return (
        <div className="bg-container">
            <Container>
                <Card>
                    <Card.Body>
                        <Card.Title>{t('title')}</Card.Title>
                        <hr/>
                        {
                            errorcode == "SubscriptionNotPerformed" ?
                                <>
                                    <Card.Text>{t('subscriptionNotPerformed')}</Card.Text>
                                    <Card.Text>{t('subscriptionNotPerformedInfo')}</Card.Text>
                                    <hr/>
                                    <Link to={'/payment/' + id} className="btn btn-primary">{t('proceedToPaymentPage')}</Link>
                                </>
                                : null
                        }
                        {
                            errorcode == "PendingCharge" ?
                                <>
                                    <Card.Text>{t('pendingCharge')}</Card.Text>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: t('checkPaymentAtPortalLink').replace('#Link', portalLink) }}></Card.Text>
                                    <hr/>
                                    <Link to="/" className="btn btn-secondary">{t('goBack')}</Link>
                                </>
                                : null
                        }
                        {
                            errorcode != "SubscriptionNotPerformed" && errorcode != "PendingCharge" ?
                                <>
                                    <Card.Text>{t('gotSomePaymentIssue')}</Card.Text>
                                    <Card.Text dangerouslySetInnerHTML={{ __html: t('checkPaymentAtPortalLink').replace('#Link', portalLink) }}></Card.Text>
                                    <hr/>
                                    <Link to="/" className="btn btn-secondary">{t('goBack')}</Link>
                                </>
                                : null
                        }
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}