import React from "react";

function YoutubeLogo() {
  return (
    <a
      href="https://www.youtube.com/channel/UCmPYgyEUc6TI_Btel3j5p-Q"
      target="_blank"
      rel="noreferrer"
    >
      <img alt="" src="/emailTemplateImg/youtube.png" />{" "}
    </a>
  );
}

function InstagramLogo() {
    return (
      <a
        href="https://www.youtube.com/channel/UCmPYgyEUc6TI_Btel3j5p-Q"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="" src="/emailTemplateImg/instagram.png" />{" "}
      </a>
    );
  }

  function FacebookLogo() {
    return (
      <a
        href="https://www.youtube.com/channel/UCmPYgyEUc6TI_Btel3j5p-Q"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="" src="/emailTemplateImg/facebook.png" />
      </a>
    );
  }

  function Logo() {
    return (
      <a
        href="https://www.youtube.com/channel/UCmPYgyEUc6TI_Btel3j5p-Q"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="" src="/emailTemplateImg/logo.png" />{" "}
      </a>
    );
  }

  function Mosaic() {
    return (
        <img alt="" src="/emailTemplateImg/mosaique.png" />
    );
  }
export {YoutubeLogo, InstagramLogo, FacebookLogo, Logo, Mosaic };