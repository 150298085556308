// export const tokenBN={
//     "userToken": {
//         "accessToken": "Dl9KR_WZTb6n0mn3fqar4w",
//         "refreshToken": "MEN6Zlf-R3KJ2JcWoWq6uw",
//         "expiresAt": "2021-11-24T16:34:55.316705085Z",
//         "expiresIn": 7200
//     },
//     "familyToken": {
//         "accessToken": "NG9SsjIdSESD3h_lF7EcNw",
//         "refreshToken": "MI9BK6syQZGzTqGF3QhzYw",
//         "expiresAt": "2021-11-24T16:34:55.317384853Z",
//         "expiresIn": 7200
//     }
// }

export const CompanyId = "06608260-14c6-11ec-8a97-fa163e0d22b0";
export const CompanyName = "Jaw Games";
export const UrlApiSite = "https://api-site.jaw.games";
export const UrlApiJaw = "https://api.jaw.games";
//export const UrlApiJaw = "http://localhost:45555";
export const BackToHomeSite = "https://jaw.games/";
export const UrlPortalJaw = "https://portal.jaw.games";
export const WebappLoginPage = "https://webapp.jaw.games/welcome";
export const NewRegisterPage = "https://jaw.games/checkout/criar-conta";
export const UrlApi = process.env.REACT_APP_API_BASE_URL ?? "https://us-central1-jawgames-wave-development-e355.cloudfunctions.net";

// firebase config
export const firebaseApiKey = process.env.FIREBASE_API_KEY ?? 'AIzaSyCsG-T3z_4qkCFDFvd6vGsCA8hW9Tple4I';
export const firebaseAuthDomain = process.env.FIREBASE_AUTH_DOMAIN ?? 'jawgames-production-6aaf.firebaseapp.com';
export const firebaseProjectId = process.env.FIREBASE_PROJECT_ID ?? 'jawgames-production-6aaf';
export const firebaseStorageBucket = process.env.FIREBASE_STORAGE_BUCKET ?? 'jawgames-production-6aaf.appspot.com';
export const firebaseMessagingSenderId = process.env.FIREBASE_MESSAGING_SENDER_ID ?? '368556915560';
export const firebaseAppId = process.env.FIREBASE_APP_ID ?? '1:368556915560:web:e7d1dc0e1a7862524674dd';

export const Countries = [
    { id: 1, name: "Brasil", code: "55", lang: "pt-BR" },
    { id: 2, name: "United States", code: "1", lang: "en-US" },
    { id: 3, name: "México", code: "54", lang: "en-US" },
    { id: 4, name: "Argentina", code: "54", lang: "en-US" },
    { id: 5, name: "Ecuador", code: "593", lang: "en-US" },
    { id: 6, name: "Paraguay", code: "595", lang: "en-US" },
    { id: 7, name: "Chile", code: "56", lang: "en-US" },
    { id: 8, name: "Surinam", code: "597", lang: "en-US" },
    { id: 9, name: "Guyane Francesa", code: "594", lang: "en-US" },
    { id: 10, name: "Venezuela", code: "58", lang: "en-US" },
    { id: 11, name: "Uruguay", code: "598", lang: "en-US" },
    { id: 12, name: "Perú", code: "51", lang: "en-US" },
    { id: 13, name: "Guyana", code: "592", lang: "en-US" },
    { id: 14, name: "Colombia", code: "57", lang: "en-US" },
    { id: 15, name: "British Virgin Islands", code: "1284", lang: "en-US" },
    { id: 16, name: "Bolivia", code: "591", lang: "en-US" },
    { id: 17, name: "Falkland Islands", code: "500", lang: "en-US" },
    { id: 18, name: "Aruba", code: "297", lang: "en-US" },
];

export const PaymentGatewayType = {
    Mundipagg: "MundiPagg",
    PayPal: "PayPal"
}

//PROD
export const PayPalClientId = "AQJE2dppSTL_O8WjlFpZdBI1YJS68H_O73bDbJS82bzVHcPWHSpn9rZC7-TkMMbuyu64AEWG7FSt9FOL";

//DEV
//export const PayPalClientId = "AXsoy9rE5wWb1dJNZOXOBgKoqq39f8De6SuH0SB_Pd8qds-5yuEhnXnR3qKxtNrEmijmSkjyqJw4W95d";