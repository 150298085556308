import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import api from '../../services/api';
import "./QRCode.css";

export default function QRCodeScreen(props) {
    const [code, setCode] = useState(undefined);
    const codeActivationUrl = 'cliente.jaw.games/ativar';

    useEffect(() => {
        if (!code) {
            api.post('/deviceAuth/codes')
                .then(response => {
                    if (!response.headers['location']) throw Error('invalid auth code');
                    setCode(response.headers['location'].replace('/', ''));
                });
        }
    }, [code]);

    useEffect(() => {
        const intervalID = setInterval(async () => {
            if (!code) return;

            try {
                const response = await api.get(`/deviceAuth/${code}`)
                if (response.data.token) {
                    console.log('User logged in');
                    let objSend = JSON.stringify({
                        message: "telecall-login",
                        payload: response.data.token,
                    });
                    window.top.postMessage(objSend, "*");
                }
            } catch (error) {
                if (error.status === 404) {
                    setCode(undefined)
                }
            }
        }, 2000);

        return () => {
            clearInterval(intervalID);
        };
    }, [code]);

    if (!code)
        return <div className="loading">
            <FontAwesomeIcon spin={true} icon={faSpinner} />
        </div>

    return <div className="qrCodeScreen">
        <div className="qrCodecontainer">
            <div className="qrCodeColumn">
                <div className="qrCode">
                    <QRCode value={`https://${codeActivationUrl}?code=${code}`} size={500} />
                </div>
            </div>
            <div className="codeColumn">
                <div className="title">
                    Ative sua conta
                </div>
                <div className="content">
                    <p>1. No seu celular ou computador, acesse: <b>{codeActivationUrl}</b> ou aponte a câmera do seu celular para o QR Code.</p>
                    <p>2. Insira o código abaixo e selecione ativar</p>
                    <div className="code">
                        {code}
                    </div>
                </div>
            </div>
        </div>
    </div>
}