import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Dropdown, Col } from "react-bootstrap";
import "../styles/flag-icons.css";

export default function ChangeLanguages(props) {
    const { id } = useParams();
    const { t, i18n } = useTranslation('email-confirmation-page');
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <>
            <div className="change-language-modal">
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" variant="link">
                            {t('laguagesLabel')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                href="#"
                                active={i18n.language.startsWith('pt')}
                                onClick={() => changeLanguage('pt-BR')}
                            ><span className="flag-icon flag-icon-br"></span> {t('languagePt')}</Dropdown.Item>
                            <Dropdown.Item
                                href="#"
                                active={i18n.language.startsWith('en')}
                                onClick={() => changeLanguage('en-US')}
                            ><span className="flag-icon flag-icon-us"></span> {t('languageEn')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </div>
        </>
    )
}