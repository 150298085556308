import { getAuth } from "firebase/auth";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import DeviceAuthForm from '../../components/Login/DeviceAuthForm';
import LoginOtpForm from '../../components/Login/OtpForm';
import LoginPasswordForm from '../../components/Login/PasswordForm';
import QRCodeScreen from "../../components/QRCode";
import useDocumentTitle from "../../components/useDocumentTitle";
import Logo from "../../images/logo-jaw-white.svg";
import api from '../../services/api';
import "../../styles/login.css";
import "../../styles/pageForm.css";
import { isMobileDevice, isTvDevice } from "../../utils";
import CustomerOnboarding from "../customerOnboarding";
import NeoForm from "../../components/Login/NeoForm";

export default function Login(props) {
  window.dataLayer.push({
    'event': 'page_loaded',
    'page_details': {
      'path': '/login',
      'brand': 'Jaw Games',
      'type': isMobileDevice() ? 'webview' : 'desktop',
    }
  });

  const { t, i18n } = useTranslation('login-page');

  const firebaseAuth = getAuth();
  const location = useLocation();
  const { pathname } = location;
  let [searchParams, setSearchParams] = useSearchParams();

  const [blacknutTokens, setBlacknutTokens] = useState(undefined);

  useDocumentTitle("Login")

  const [authType, setAuthType] = useState("byPhone");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const onSubmit = async (response) => {
    let responseData = response.data;
    responseData.userToken.createdAt = new Date();
    responseData.familyToken.createdAt = new Date();

    let objSend = JSON.stringify({
      message: "telecall-login",
      payload: responseData,
    });

    if (Boolean(response.headers['x-signup'])) {
      setBlacknutTokens(objSend);
    } else {
      window.top.postMessage(objSend, "*");
    }

    if (searchParams.get('code')) {
      const idToken = await firebaseAuth.currentUser.getIdToken();
      api.post(`/customers/activate-device`, { authCode: searchParams.get('code'), token: responseData }, {
        headers: {
          'Authorization': idToken
        }
      })
        .then(() => { window.location = 'https://jaw.games' })
    }
  }

  if (blacknutTokens)
    return <CustomerOnboarding blacknutTokens={blacknutTokens} />;

  if (isTvDevice())
    return <QRCodeScreen />;

  return (
    <>
      <div className="Login">
        <div className="background">
          <div className="leftPanel">
            <div className="leftPanelContent">
              <img class="logo-mobile" src={Logo} alt="logo-jaw-games" />
              <div className="leftPanelTitle">
                {/* {t('leftPanelTitle')} */}
              </div>
              <div className="leftPanelSubtitle">
                {/* {t('leftPanelSubtitle')} */}
              </div>
            </div>
          </div>
          <div className="rightPanel">
            {pathname === '/ativar' && !searchParams.get('code') ? <>
              <div className="form">
                <img class="logo-desktop" src={Logo} alt="logo-jaw-games" />
                <DeviceAuthForm />
                <div className="formFooter">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" variant="link">
                      {t('laguagesLabel')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        active={i18n.language.startsWith('pt')}
                        onClick={() => changeLanguage('pt-BR')}
                      >{t('languagePt')}</Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        active={i18n.language.startsWith('en')}
                        onClick={() => changeLanguage('en-US')}
                      >{t('languageEn')}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
              : <>
                <div className="form">
                  {/* <img class="logo-desktop" src={Logo} alt="logo-jaw-games" /> */}
                  {authType === "byPassword" && <LoginPasswordForm /> }
                  {authType === "byPhone" && <LoginOtpForm onSubmit={(token) => onSubmit(token)} /> }
                  {authType === "byNeo" && <NeoForm onSubmit={(token) => onSubmit(token)} /> }
                  <div className="formFooter">
                    {/* <div className="pt-1">
                      <Link to="/recovery-password" className="white">{t('forgotPasswordLabel')}</Link>
                    </div> */}
                    <div className="pt-1">
                    {authType !== "byNeo" ? <a href='#' className="white" onClick={() => setAuthType('byNeo')}>{t('providerAuthLabel')}</a>
                    : <a href='#' className="white" onClick={() => setAuthType('byPhone')}>{t('phoneAuthLabel')}</a>}
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" variant="link">
                          {t('laguagesLabel')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#"
                            active={i18n.language.startsWith('pt')}
                            onClick={() => changeLanguage('pt-BR')}
                          >{t('languagePt')}</Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            active={i18n.language.startsWith('en')}
                            onClick={() => changeLanguage('en-US')}
                          >{t('languageEn')}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="signUpCard">
                  <span className="cardTitle">{t('signUpCardTitle')}</span>
                  <a href="https://jaw.games/dashboard/assinatura-pagamento/"><span className="cardDescription">{t('signUpCardDescription')}</span></a>
                  {/* <span className="cardInfo">* {t('signUpCardInfo')}</span> */}
                </div>
              </>}
          </div>
        </div>
      </div>
    </>
  )
}