import { Button } from "react-bootstrap";
import IconAndroid from "../images/icons/icon-android.svg";
import IconApple from "../images/icons/icon-apple.svg";
import IconGoogleTv from "../images/icons/icon-google-tv.svg";
import IconWindows from "../images/icons/icon-windows.svg";
import Logo from "../images/logo-jaw-white.svg";
import '../styles/customer-onboarding.css';
import { isMobileDevice } from "../utils";
import { useSearchParams } from "react-router-dom";

export default function CustomerOnboarding({ blacknutTokens }) {
    const [searchParams, setSearchParams] = useSearchParams();
    window.dataLayer.push({
        'event': 'page_loaded',
        'page_details': {
            'path': '/boas-vindas',
            'brand': 'Jaw Games',
            'type': isMobileDevice() ? 'webview' : 'desktop',
        }
    });

    function startGame() {
        window.top.postMessage(blacknutTokens, "*");
    }

    return (<div className="page">
        <div className="heroBackground"></div>
        <div className="hero">
            <div className="navbar">
                <img class="logo" src={Logo} alt="logo-jaw-games" />
            </div>
            <div className="pageRow">
                <div className="pageColumn50">
                    <div className="title">Parabéns!</div>
                    <div className="textContent">
                        {!searchParams.get('promo_code') ? <div className="paragraph">
                            Você recebeu um presente da Jaw e agora você pode desfrutar de <b>7 dias</b> inteiramente grátis de Jaw Games.
                        </div> :
                        <div className="paragraph">
                        Parabéns! Você utilizou o promocode <b>{searchParams.get('promo_code')}</b> e ganhou 15 dias grátis.
                        Após esse período, você deverá cadastrar um cartão de crédito válido para continuar jogando.
                        Não se preocupe! Você não será cobrado durante seu período de gratuidade e poderá cancelar quando quiser.
                        </div>}
                        <div className="paragraph">
                            Com a Jaw Games, você tem acesso a uma incrível seleção de mais de 500 jogos, tudo em um único aplicativo. Em nossa biblioteca, você encontra jogos de diversas categorias, incluindo ação, estratégia, RPG, corrida, jogos infantis e muito mais.
                        </div>
                        <div className="paragraph">
                            A melhor parte? Você pode jogar de qualquer lugar, em qualquer dispositivo. Então o que está esperando? Sua jornada no mundo do cloud gaming começa agora.
                        </div>
                        <Button data-section="section_modulo-conteudo" data-element="button_jogar-agora" size="lg" onClick={() => startGame()}>
                            Jogar agora
                        </Button>
                        <div className="paragraph">
                            <i>Saiba mais sobre Jaw Games visitando nosso site: <a data-section="section_modulo-conteudo" data-element="link_jaw-games"
                                className="pageLink" href="https://jaw.games" target="_blank" rel="noreferrer">jaw.games</a>.</i><br />
                            <i>Para suporte clique <a data-section="section_modulo-conteudo" data-element="link_suporte"
                                className="pageLink" href="https://jaw.games/atendimento" target="_blank" rel="noreferrer">aqui</a>.</i>
                        </div>
                    </div>
                </div>
                <div className="pageColumn">

                </div>
            </div>
        </div>
        <div className="pageContainer">
            <div className="pageRow">
                <div className="pageColumn">
                    <div className="sectionTitle">
                        Baixe na sua plataforma preferida
                    </div>
                    <div className="pageColumn50">
                        <div className="paragraph">
                            Jogos ilimitados em seus dispositivos favoritos, disponível para computador, celular, tablet e Smart TV. Também jogue no seu navegador.
                        </div>
                    </div>
                    <div className="platformGrid">
                        <div className="item">
                            <div className="platform">
                                <div className="platformImg">
                                    <img src={IconWindows} alt="icon-windows" />
                                </div>
                                <div className="platformName">
                                    Computador: Windows<br />
                                    10 or later
                                </div>
                            </div>
                            <Button data-section="section_modulo-lojas" data-element="button_loja-windows" href="https://file.jaw.games/JawGames.exe" target="_blank" rel="noreferrer">
                                Jogar agora
                            </Button>
                        </div>
                        <div className="item">
                            <div className="platform">
                                <div className="platformImg">
                                    <img src={IconApple} alt="icon-apple" />
                                </div>
                                <div className="platformName">
                                    Computador: MacOS<br />
                                    11 or later
                                </div>
                            </div>
                            <Button data-section="section_modulo-lojas" data-element="button_loja-macos" href="https://file.jaw.games/JawGames.dmg" target="_blank" rel="noreferrer">
                                Jogar agora
                            </Button>
                        </div>
                        <div className="item">
                            <div className="platform">
                                <div className="platformImg">
                                    <img src={IconApple} alt="icon-apple" />
                                </div>
                                <div className="platformName">
                                    Celular: iOS<br />
                                    14.5 or later - Safari
                                </div>
                            </div>
                            <Button data-section="section_modulo-lojas" data-element="button_loja-apple" href="https://jaw.games/como-jogar/como-instalar-app-ios" target="_blank" rel="noreferrer">
                                Jogar agora
                            </Button>
                        </div>
                        <div className="item">
                            <div className="platform">
                                <div className="platformImg">
                                    <img src={IconAndroid} alt="icon-android" />
                                </div>
                                <div className="platformName">
                                    Celular: Android<br />
                                    5.0 or later
                                </div>
                            </div>
                            <Button data-section="section_modulo-lojas" data-element="button_loja-android" href="https://play.google.com/store/apps/details?id=com.telecall.jawgames" target="_blank" rel="noreferrer">
                                Jogar agora
                            </Button>
                        </div>
                        <div className="item">
                            <div className="platform">
                                <div className="platformImg">
                                    <img src={IconGoogleTv} alt="icon-google-tv" />
                                </div>
                                <div className="platformName">
                                    Smart TV: Android<br />
                                    10 or later
                                </div>
                            </div>
                            <Button variant="secondary" disabled="true">
                                Em breve
                            </Button>
                        </div>
                        <div className="item">
                            <div className="platform">
                                <div className="platformImg">
                                    <img src={IconGoogleTv} alt="icon-google-tv" />
                                </div>
                                <div className="platformName">
                                    Google TV<br />
                                    Chromecast
                                </div>
                            </div>
                            <Button variant="secondary" disabled="true">
                                Em breve
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pageFooter">
            </div>
        </div>
    </div >)
}