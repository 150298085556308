import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Button, InputGroup, Spinner } from "react-bootstrap";
import { Formik } from 'formik';
import * as yup from 'yup';
import InputMask from "react-input-mask";
import Swal from 'sweetalert2';

import { CompanyId } from '../consts';
import apijaw from "../services/api-jaw";
import useDocumentTitle from "../components/useDocumentTitle";

import "../styles/pageForm.css";
import "../styles/renew-subscription.css";
import LogoAzul from "../images/logo-azul.png";
import VisaLogo from "../images/Visa.png";
import MastercardLogo from '../images/Mastercard.png'
import useQueryParams from "../components/useQueryParams";

export default function RenewSubscription(props) {
    const { id } = useParams();
    const { t, i18n } = useTranslation('payment-page');
  
    const [customer, setCustomer] = useState({});

    const [paymentGateways, setPaymentGateways] = useState([]);

    const [products, setProducts] = useState([]);

    const [sending, setSending] = useState(false);

    const [form, setForm] = useState({
        productId: '',
        paymentGatewayId: '',
        creditCardNumber: '',
        creditCardBrand: '',
        cardHolderName: '',
        goodThruMonth: 1,
        goodThruYear: new Date().getFullYear(),
        creditCardCvv: '',
        discountCoupon: '',
    });

    const queryParams = useQueryParams();

    useDocumentTitle(t('title'))
    
    useEffect(() => {
        initialLoad();
    }, []);

    const brands = [
        { id: 0, name: 'Visa', image: VisaLogo },
        { id: 1, name: 'Mastercard', image: MastercardLogo }
    ];

    const months = [
    ];

    for (let month = 1; month <= 12; month++) {
        months.push({ id: month });
    }

    const years = [
    ];

    for (let year = new Date().getFullYear(); year <= (new Date().getFullYear() + 10); year++) {
        years.push({ id: year });
    }
    
    //#region Methods
     
    const initialLoad = async () => {
        const customerRequest = getCustomer()
        let customerData;

        const paymentGatewaysRequest = getPaymentGateways()
        let PaymentGatewaysData
        
        const productsRequest = getProducts()
        let productsData;
        
        try {
            const response = await customerRequest
            if (response.data.success
                && response.data?.data?.customer != undefined
                && response.data?.data?.customer.companyId == CompanyId) {
                    customerData = response.data.data.customer;
            }
            else {
                return Swal.fire({
                    icon: 'error',
                    text: t('invalidUserMessage'),
                }).then(function (result) {
                    window.location.href = "/";
                });
            }
        } catch (error) {
            return Swal.fire({
                    icon: 'error',
                    text: t('invalidUserMessage'),
                }).then(function (result) {
                    window.location.href = "/";
            });
        }

        try {
            const response = await paymentGatewaysRequest
           
            if (response.data.success
                && response.data.data.paymentGateways.length > 0) {
                    PaymentGatewaysData = response.data.data.paymentGateways;
            }
            else {
                return Swal.fire({
                    icon: 'error',
                    text: t('noPaymentGatewayAvailableMessage'),
                }).then(function (result) {
                    window.location.href = "/";
                });
            }
        } catch (error) {
            return Swal.fire({
                icon: 'error',
                text: t('noPaymentGatewayAvailableMessage'),
            }).then(function (result) {
                window.location.href = "/";
            });
        }

        try {
            const response = await productsRequest
            if (response.data.success
                && response.data.data.products.length > 0) {
                    productsData = response.data.data.products;
                }
            else {
                return Swal.fire({
                    icon: 'error',
                    text: t('noProductAvailableMessage'),
                }).then(function (result) {
                    window.location.href = "/";
                });
            }
        } catch (error) {
            return Swal.fire({
                icon: 'error',
                text: t('noProductAvailableMessage'),
            }).then(function (result) {
                window.location.href = "/";
            });
        }

        setCustomer(customerData);
        setProducts(productsData);
        setPaymentGateways(PaymentGatewaysData);

        let initialPlan;
        if (queryParams.get("plan")) {
            initialPlan = productsData.find(p => 
                p.translationKey && 
                p.translationKey.toLowerCase() === queryParams.get("plan")?.toLowerCase());
        }

        let initialPaymentGateway; 
        if (PaymentGatewaysData.length === 1) {
            initialPaymentGateway = PaymentGatewaysData[0];
        }

        let newForm = { ...form };
        newForm.paymentGatewayId = initialPaymentGateway?.id;
        newForm.productId = initialPlan?.id;

        setForm(newForm);
    };

    const getCustomer = async () => {
        return await apijaw
            .get("/Customer/" + id, {
                headers: {
					'Accept-Language': i18n.language
				}
            });
    }

    const getProducts = async () => {
        return await apijaw
            .get("/Product/Search?companyId=" + CompanyId, {
                headers: {
					'Accept-Language': i18n.language
				}
            });
    }

    const getPaymentGateways = async () => {
        return await apijaw
            .get("/PaymentGateway/Search?companyId=" + CompanyId, {
                headers: {
					'Accept-Language': i18n.language
				}
            });
    }

    const handleSubmitThis = (values) => {
        setSending(true);
        apijaw
            .post("/Subscription", {
                productId: values.productId,
                customerId: id,
                paymentGatewayId: values.paymentGatewayId,
                creditCard: {
                    customerId: id,
                    number: removeNonNumeric(values.creditCardNumber),
                    brand: values.creditCardBrand,
                    cardHolderName: values.cardHolderName.toUpperCase(),
                    goodThru: new Date(values.goodThruYear, values.goodThruMonth, 1),
                    cvv: removeNonNumeric(values.creditCardCvv),
                },
                discountCoupon: values.discountCoupon
            },
            {
                headers: {
					'Accept-Language': i18n.language
				}
            })
            .then((response) => {
                setSending(false);
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: (values.discountCoupon.length > 0 ? `${t('couponProcessed')}\n` : "") + t('successMessage'),
                    }).then(function (result) {
                        window.location.href = "/login";
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: t('couldntSendRequestMessage'),
                    });
                }
            })
            .catch((error) => {
                setSending(false);
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('requestFaliureMessage')
                    });
                }
            });
    };

    //#endregion

    //#region Validations

    const isValidCardHolderName = (value) => {
        return value != undefined
            && removeNonNumeric(value).length == 0
            && removeNumbers(value).length >= 5
            && removeNumbers(value).length <= 20;
    }

    const isValidCardNumber = (value) => {
        return value != undefined
            && removeNonNumeric(value).length >= 16
            && removeNonNumeric(value).length <= 19;
    }

    const isValidCvv = (value) => {
        return value != undefined
            && removeNonNumeric(value).length >= 3
            && removeNonNumeric(value).length <= 4
    }

    //#endregion

    //#region Helper methods

    const removeNonNumeric = (text) => {
        return text?.replace(/\D/g, '');
    }

    const removeNumbers = (text) => {
        return text?.replace(/\d/g, '');
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }
    
    //#endregion

    const schema = yup.object().shape({
        productId: yup.string().required(),
        paymentGatewayId: yup.string().required(),
        creditCardNumber: yup.string().test('test-creditCardNumber', 'Número de catão inválido', (value, context) => isValidCardNumber(value)),
        creditCardBrand: yup.number().required().oneOf(brands.map(b => b.id), 'A bandeira do cartão deve ser selecionada.'),
        cardHolderName: yup.string().test('test-cardHolderName', 'Nome do cartão obrigatório.', (value, context) => isValidCardHolderName(value)),
        goodThruMonth: yup.number().required().oneOf(months.map(b => b.id), 'O mês deve ser selecionado.'),
        goodThruYear: yup.number().required().oneOf(years.map(b => b.id), 'O ano deve ser selecionado.'),
        creditCardCvv: yup.string().test('test-creditCardCvv', 'Código de segurança inválido.', (value, context) => isValidCvv(value)),
    });

    return (
        <>
            <div className="bg-container">
                <Container>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        onSubmit={handleSubmitThis}
                        initialValues={form}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col className="mb-3 text-center">
                                        <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col className="mb-3 text-center">
                                        <h4 className="text-center text-info">{t('choosePlanLabel')}</h4>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center renew-subscription-product-plan-card">
                                    {
                                        products.filter(function (product) {
                                            return product.isExternalProductControl === false;
                                        }).map(({ id, name, translationKey, priceBrl, priceUsd }) => (
                                            <Col key={id} md={3} className="text-center renew-subscription-product-plan-card">
                                                <Card style={{ width: '18rem' }}>
                                                    <Card.Body>
                                                        <Card.Title>
                                                        { translationKey != undefined && translationKey != null
                                                        ? t('products.'+capitalizeFirstLetter(translationKey)) 
                                                        : name
                                                        }
                                                        </Card.Title>
                                                        <Form.Check
                                                            type="radio"
                                                            name="productId"
                                                            label={
                                                                customer.countryId == 1
                                                                    ?
                                                                    priceBrl.toLocaleString('pt-BR', {
                                                                        style: 'currency',
                                                                        currency: 'BRL',
                                                                    })
                                                                    :
                                                                    priceUsd.toLocaleString('en-US', {
                                                                        style: 'currency',
                                                                        currency: 'USD',
                                                                    })
                                                            }
                                                            value={id}
                                                            onChange={handleChange}
                                                            checked={values.productId === id}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <hr className="separator" />
                                <Row className="justify-content-md-center, renew-subscription-creditcard">
                                    {
                                        brands.map(({ id, name, image }) => (
                                            <Col key={id} md={1} className="mb-3 text-center">
                                                <ul className="brands">
                                                    <li>
                                                        <img
                                                            src={image}
                                                            alt={name}
                                                        />
                                                    </li>
                                                    <li className="text-center">
                                                        <Form.Check
                                                            type="radio"
                                                            name="creditCardBrand"
                                                            value={id}
                                                            onChange={handleChange}
                                                        />
                                                    </li>
                                                </ul>
                                            </Col>
                                        ))
                                    }
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="flCardHolderName">
                                            <Form.Label>{t('cardHolderNameLabel')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="cardHolderName"
                                                maxLength={20}
                                                value={values.cardHolderName}
                                                onChange={handleChange}
                                                isInvalid={errors.cardHolderName}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="flCreditCardNumber">
                                            <Form.Label>{t('cardNumberLabel')}</Form.Label>
                                            <InputMask
                                                type="text"
                                                name="creditCardNumber"
                                                value={values.creditCardNumber}
                                                onChange={handleChange}
                                                isInvalid={errors.creditCardNumber}
                                                className={`form-control 
                                                ${touched.creditCardNumber && !errors.creditCardNumber ? "is-valid" : ""}
                                                ${touched.creditCardNumber && errors.creditCardNumber ? "is-invalid" : ""}`}
                                                mask={'9999-9999-9999-9999'} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="flGoodThru">
                                            <Form.Label>{t('goodThruLabel')}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Select
                                                    name="goodThruMonth"
                                                    value={values.goodThruMonth}
                                                    onChange={handleChange}
                                                    isInvalid={errors.goodThruMonth}
                                                >
                                                    {
                                                        months.map(({ id }) => (
                                                            <option key={id} value={id}>{(id + '').padStart(2, '0')}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <InputGroup.Text>/</InputGroup.Text>
                                                <Form.Select
                                                    name="goodThruYear"
                                                    value={values.goodThruYear}
                                                    onChange={handleChange}
                                                    isInvalid={errors.goodThruYear}
                                                >
                                                    {
                                                        years.map(({ id }) => (
                                                            <option key={id} value={id}>{id}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="flCreditCardCvv">
                                            <Form.Label>{t('cvvLabel')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="creditCardCvv"
                                                maxLength={4}
                                                value={values.creditCardCvv}
                                                onChange={handleChange}
                                                isInvalid={errors.creditCardCvv}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr className="separator" />
                                <Row className="justify-content-md-center">
                                    <Col md={6}>
                                        <div className="formboxWrapper">
                                            <h5>{t('giftVoucherLabel')}</h5>
                                            <h5>{t('promotionalCouponLabel')}</h5>
                                            <Form.Control
                                                type="text"
                                                name="discountCoupon"
                                                value={values.discountCoupon}
                                                onChange={handleChange}
                                                isInvalid={errors.discountCoupon}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col className="text-center">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="btn-round"
                                            disabled={!isValid}>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className={!sending ? 'visually-hidden spinner-right-margin' : 'spinner-right-margin'}
                                            />
                                            {t('buttonLabel')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </div>
        </>
    )
}