import React from 'react';
import { Form, FloatingLabel, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { decode as Base64Decode } from 'base-64'
import LogoAzul from "../images/logo-azul.png";
import BackImage from "../images/webdoor.png";
import "../styles/change-password.css";
import { Link } from 'react-router-dom';
import apijaw from "../services/api-jaw";
import { useTranslation } from 'react-i18next';

class ChangePassword extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: Base64Decode(this.getQueryVariable("email")),
      token: Base64Decode(this.getQueryVariable("token")),
      newPassword: "",
      confirmPassword: ""
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
  }

  validateForm() {
    return this.state.email.length > 0
      && this.state.newPassword.length >= 8
      && this.state.newPassword == this.state.confirmPassword;
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  handleSubmit = () => {
    apijaw
      .post("/Customer/ChangePassword", {
        Token: this.state.token,
        Email: this.state.email,
        NewPassword: this.state.newPassword
      })
      .then((response) => {
        if (response.data.success === true) {
          Swal.fire({
            icon: 'success',
            text: 'Senha alterada com sucesso.',
          }).then(function (result) {
            window.location.href = "/";
          });
        } else {
          Swal.fire({
            icon: 'info',
            text: 'Não foi possivel enviar a requisição no momento, tente novamente mais tarde.',
          });
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors != undefined) {
          let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
          Swal.fire({
            icon: 'error',
            html: message
          });
        }
        else {
          Swal.fire({
            icon: 'error',
            text: 'Não foi possivel enviar a requisição no momento, tente novamente mais tarde.'
          });
        }
      });
  };

  render() {
    return (
      <>
        <div className="login-container">
          <div
            className="section-webdoor"
            style={{
              backgroundImage: `url(${BackImage})`,
            }}
          >
            <div className="content-change-password-with-email">
              <Form>
                <p style={{ fontSize: "1.4em" }} className="text-center">
                  <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                </p>
                <FloatingLabel
                  controlId="flNewPassword"
                  label="New Password"
                  className="mb-3"
                >
                  <Form.Control
                    autoFocus
                    type="password"
                    name="newPassword"
                    required
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="flConfirmPassword"
                  label="Confirm Password"
                  className="mb-3"
                >
                  <Form.Control
                    autoFocus
                    type="password"
                    name="confirmPassword"
                    required
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                  />
                </FloatingLabel>

                <Button
                  className="d-grid gap-2 button"
                  style={{ backgroundColor: "#26AAE2" }}
                  size="lg"
                  onClick={this.handleSubmit}
                  disabled={!this.validateForm()}
                >
                  Send
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ChangePassword;