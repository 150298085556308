import React, { Suspense, useState } from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ApprovedPayment from '../src/temp/approvedPayment/approvedPayment';
import EmailTemplate from '../src/temp/email/emailTemplate';
import OrderFulfilled from '../src/temp/orderFulfilled/orderFulfilled';
import { firebaseApiKey, firebaseAppId, firebaseAuthDomain, firebaseMessagingSenderId, firebaseProjectId, firebaseStorageBucket } from './consts';
import ChangePassword from './pages/changePasswordWithEmail';
import ChangePasswordWithPhone from './pages/changePasswordWithPhone';
import EmailConfirmation from './pages/emailConfirmation';
import EmailRegister from './pages/emailRegister';
import Login from './pages/login';
import NotFound from './pages/notFound';
import NuuvemIntegration from './pages/nuuvemIntegration';
import Payment from './pages/payment';
import PaymentIssues from './pages/payment-issues';
import RecoveryPassword from './pages/recoveryPassword';
import Registration from './pages/registration';
import RenewSubscription from './pages/renewSubscription';
import TermAcceptance from './pages/termAcceptance';

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
};

initializeApp(firebaseConfig);

function App() {
  const [showLogin, setShowLogin] = useState(true);
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      const authRedirectUrl = urlParams.get('authRedirectUrl');
      if (authRedirectUrl) {
        window.location = `${authRedirectUrl}?phoneNumber=${user.phoneNumber.replace('+', '')}&token=${auth.currentUser.accessToken}`
      }
    } else {
      console.log('User is signed out');
    }
    setShowLogin(false);
  });

  return (<>{!showLogin &&
    <Routes>
      <Route exact path="/" element={<Suspense fallback="loading"><Login /></Suspense>} />
      <Route exact path="/login" element={<Suspense fallback="loading"><Login /></Suspense>} />
      <Route exact path="/ativar" element={<Suspense fallback="loading"><Login /></Suspense>} />
      <Route exact path="/nuuvem-integration/:email/:activationToken" element={<Suspense fallback="loading"><NuuvemIntegration /></Suspense>} />
      <Route exact path="/email-register" element={<Suspense fallback="loading"><EmailRegister /></Suspense>} />
      <Route exact path="/recovery-password" element={<Suspense fallback="loading"><RecoveryPassword /></Suspense>} />
      <Route exact path="/registration" element={<Suspense fallback="loading"><Registration /></Suspense>} />
      <Route exact path="/payment/:id" element={<Suspense fallback="loading"><Payment /></Suspense>} />
      <Route exact path="/renew-subscription/:id" element={<Suspense fallback="loading"><RenewSubscription /></Suspense>} />
      <Route exact path="/change-password" element={<Suspense fallback="loading"><ChangePassword /></Suspense>} />
      <Route exact path="/change-password-with-phone" element={<Suspense fallback="loading"><ChangePasswordWithPhone /></Suspense>} />
      <Route exact path="/email-confirmation/:id" element={<Suspense fallback="loading"><EmailConfirmation /></Suspense>} />
      <Route exact path="/term-acceptance/:id" element={<Suspense fallback="loading"><TermAcceptance /></Suspense>} />
      <Route exact path="/payment-issues/:id/:errorcode" element={<Suspense fallback="loading"><PaymentIssues /></Suspense>} />
      <Route path="*" element={<Suspense fallback="loading"><NotFound /></Suspense>} />

      {/* Region - Jaw Games - Email Template Flow */}
      <Route exact path="/email-template" element={<Suspense fallback="loading"><EmailTemplate /></Suspense>} />
      <Route exact path="/approved-payment" element={<Suspense fallback="loading"><ApprovedPayment /></Suspense>} />
      <Route exact path="/order-fulfilled" element={<Suspense fallback="loading"><OrderFulfilled /></Suspense>} />
    </Routes>
  }</>);
}

export default App;