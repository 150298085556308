import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useQueryParams from "../components/useQueryParams";
import { Container, Row, Col, Form, InputGroup, Button, Spinner, Modal, Dropdown } from "react-bootstrap";
import InputMask from "react-input-mask";
import styles from "./registration.module.css";
import logo from "../images/logo-azul.png";
import StepsPill from "../components/stepsPill";
import logoFacebook from "../images/FacebookBlue.png";
import logoGoogle from "../images/Google.png";
import Swal from "sweetalert2";
import { decode as Base64Decode } from "base-64";
import utf8 from "utf8";
import { Formik } from "formik";
import * as yup from "yup";
import { cpf } from "cpf-cnpj-validator";
import { CompanyId, Countries, WebappLoginPage } from "../consts";
import apijaw from "../services/api-jaw";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import ChangeLanguages from '../components/changeLanguage'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function Registration(props) {
	const { t, i18n } = useTranslation("registration-page");
	const queryParams = useQueryParams();
	const authTypes = [
		{ value: 0, image: null },
		{ value: 1, image: logoFacebook },
		{ value: 2, image: logoGoogle },
	];

	const [step, setStep] = useState({
		current: 1,
		total: 4,
	});

	const [termHtml, setTermHtml] = useState("");

	const [form1, setForm1] = useState({
		authType: 0,
		email: "",
		password: "",
		confirmPassword: "",
	});

	const [form2, setForm2] = useState({
		showEmailField: false,
		email: "",
		name: "",
		document: "",
		contryCodePhone: Countries[0].code,
		celphone: "",
		birthdate: "",
		acceptTerm: false,
		termId: "",
		acceptAdvertising: false,
		sending: false,
	});

	useEffect(() => {
		getLatestTerm()
			.then((response) => {
				if (response.data?.data != undefined) {
					const termBase64decoded = Base64Decode(
						response.data.data.termTranslation.termHtml
					);
					const termUtf8 = utf8.decode(termBase64decoded);
					setTermHtml(termUtf8);
					setForm2((previewState) => ({
						...previewState,
						termId: response.data.data.id,
					}));
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					html: t("messages.errors.couldNotLoadTermsAndConditions"),
				}).then(function () {
					window.location.href = "/";
				});
			});
	}, []);

	const getLatestTerm = () => {
		return apijaw.get("/Term/Latest/" + CompanyId, {
			headers: {
				"Accept-Language": i18n.language,
			},
		});
	};

	const goToNextStep = () => {
		setStep((prevState) => ({
			...prevState,
			current: 2,
		}));
	};

	const goToPaymentPage = (customerId) => {
		window.location.href =
			"/payment/" +
			customerId +
			(queryParams.get("productId")
				? `?productId=${queryParams.get("productId")}`
				: "");
	};

	const removeNonNumeric = (text) => {
		return text?.replace(/\D/g, "");
	};

	const isEmail = (email) => {
		return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
	};

	const handleSubmitForm1 = (values) => {
		setForm1(values);
		if (values.authType === 1) {
			setForm2((prevState) => ({
				...prevState,
				showEmailField:
					values.email === undefined ||
					values.email === null ||
					values.email === "",
				email: values.email,
			}));
		}

		goToNextStep();
	};

	const handleSubmitForm2 = (values) => {
		setForm2({
			...values,
			sending: true,
		});
		console.log(form1, form2);

		apijaw
			.post(
				"/Customer",
				{
					companyId: CompanyId,
					termId: values.termId,
					authType: form1.authType,
					email:
						form2.email !== undefined &&
							form2.email !== null &&
							form2.email !== ""
							? form2.email
							: form1.email,
					password: form1.password,
					name: values.name,
					document: values.document.replace(/\W/g, ""),
					celphone:
						values.contryCodePhone + "" + removeNonNumeric(values.celphone),
					birthdate: values.birthdate,
					acceptNewsLetter: values.acceptAdvertising,
					acceptMarketingMessages: values.acceptAdvertising,
				},
				{
					headers: {
						"Accept-Language": i18n.language,
					},
				}
			)
			.then((response) => {
				setForm2((prevState) => ({
					...prevState,
					sending: false,
				}));
				if (response.data.success === true) {
					Swal.fire({
						icon: "success",
						html: t("messages.success.accountCreated"),
						timer: 2000
					}).then(() => {
						goToPaymentPage(response.data.data.customerId);
					});
				} else {
					Swal.fire({
						icon: "info",
						text: t("messages.errors.couldntSendRequest"),
					});
				}
			})
			.catch((error) => {
				setForm2((prevState) => ({
					...prevState,
					sending: false,
				}));
				if (error.response?.data?.errors != undefined) {
					let message =
						"<p>" +
						error.response.data.errors.map((e) => e.message).join("</p><p>") +
						"</p>";

					if (error.response?.data?.errors[0].errorCode == "EmailAlreadyInUse") {
						Swal.fire({
							icon: "error",
							html: message,
							confirmButtonText: t("messages.errors.login"),
							confirmButtonColor: '#00ace5',
							footer: `<a href="/recovery-password">${t("messages.errors.passwordRecovery")}</a>`
						}).then((result) => {
							if (result.isConfirmed) {
								window.location.href = "https://webapp.jaw.games/welcome";
							}
						});
					} else {
						Swal.fire({
							icon: "error",
							html: message
						})
					}
				} else {
					Swal.fire({
						icon: "error",
						text: t("messages.errors.requestFailed"),
					});
				}
			});
	};


	return (
		<>
			<Container fluid className={[styles.defaults, styles.header].join(" ")}>
				<Row className="justify-content-md-center">
					<Col xs lg="2"></Col>
					<Col md="auto" className="pt-3">
						<img src={logo} className={styles.logo} alt="JAW GAMES" />
					</Col>
					<Col xs lg="2"></Col>
				</Row>
			</Container>
			<Container
				fluid
				className={[styles.defaults, styles.background].join(" ")}
			>
				<Row className="justify-content-md-center">
					<Col sm className={styles.contentGrid}></Col>
					<Col sm>
						<p>
							<span className={styles.primaryText}>{t("welcome")}</span>
							<span className={styles.secondaryText}>
								{" "}
								-{" "}
								{t("steps").replace("X", step.current).replace("Y", step.total)}
							</span>
						</p>
						<StepsPill step={step}></StepsPill>
						{step.current === 1 ? (
							<Form1
								setStep={setStep}
								authTypes={authTypes}
								form={form1}
								handleSubmit={(e) => handleSubmitForm1(e)}
							></Form1>
						) : (
							<Form2
								setStep={setStep}
								termHtml={termHtml}
								form={form2}
								handleSubmit={(e) => handleSubmitForm2(e)}
							></Form2>
						)}
					</Col>
					<Col sm className={styles.contentGrid}></Col>
				</Row>
				<ChangeLanguages />
			</Container>
		</>
	);
}

function Form1(props) {
	const { t, i18n } = useTranslation("registration-page");

	const removeNonNumeric = (text) => {
		return text?.replace(/\D/g, "");
	};

	const authSocialMedia = (authType) => {
		switch (authType) {
			case 1:
				props.handleSubmit({
					...props.form,
					authType: authType,
					readOnlyCellPhoneFiled: true,
					email: removeNonNumeric("219999999"),
				});
				break;
			case 2:
				props.handleSubmit({
					...props.form,
					authType: authType,
					email: "teste@gmail.com",
				});
				break;
			default:
				console.error("Not implemented exception.");
				break;
		}
	};

	const isValidPassword = (password) => {
		return props.authType > 0 || passwordPatternTest(password);
	};

	const passwordPatternTest = (password) => {
		const regex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]).{8,50}$/;
		return regex.test(password);
	};

	const isValidConfirmPassword = (confirmPassword, context) => {
		let vals = context.from[0].value;
		return (
			confirmPassword != undefined &&
			confirmPassword.length > 0 &&
			confirmPassword == vals.password
		);
	};

	const schema = yup.object().shape({
		email: yup.string().required().email(),
		password: yup
			.string()
			.test("test-password", "Informe uma senha válida.", (value, context) =>
				isValidPassword(value)
			),
		confirmPassword: yup
			.string()
			.test("test-confirmPassword", "Confirme a senha.", (value, context) =>
				isValidConfirmPassword(value, context)
			),
	});

	// string password strength checks
	const [passwordStrong, setPasswordStrong] = useState("");
	const [messageStrong, setMessageStrong] = useState("");
	const [progressStrong, setProgressStrong] = useState("");

	const [hidePassword, setHidePassword] = useState(true);
	const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

	const handlePassword = (passwordValue) => {
		const strengthChecks = {
			length: 0,
			hasUpperCase: false,
			hasLowerCase: false,
			hasDigit: false,
			hasSpecialChar: false,
		};

		strengthChecks.length = passwordValue.length >= 8 ? true : false;
		strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
		strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
		strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
		strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

		let verifiedList = Object.values(strengthChecks).filter((value) => value);

		let strength =
			verifiedList.length == 5
				? "Strong"
				: verifiedList.length >= 2
					? "Medium"
					: "Weak";

		setPasswordStrong(passwordValue);
		setProgressStrong(`${(verifiedList.length / 5) * 100}%`);
		setMessageStrong(strength);

		console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
	};

	const getActiveColor = (type) => {
		if (type === "Strong") return "#8BC926";
		if (type === "Medium") return "#FEBD01";
		return "#FF0054";
	};

	const getActiveColorConfirmPassword = (type) => {
		if (type !== passwordStrong) return "#8BC926";
		return "#FF0054";
	};

	return (
		<>
			<main className={styles.formContainer}>
				<GoogleReCaptchaProvider reCaptchaKey="6LcYmDsdAAAAAGokLex6SC3sV082odZMuR_T3YQJ"></GoogleReCaptchaProvider>
				<Formik
					enableReinitialize={true}
					validationSchema={schema}
					onSubmit={props.handleSubmit}
					initialValues={props.form}
				>
					{({
						handleSubmit,
						handleChange,
						setFieldValue,
						values,
						touched,
						isValid,
						errors,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mb-3">
								<Form.Label>{t("form1.labelEmail")}</Form.Label>
								<div className={styles.iconsFormRegistration}>
									<Form.Control
										type="email"
										name="email"
										value={values.email}
										onChange={handleChange}
										isValid={touched.email && !errors.email}
										isInvalid={touched.email && errors.email}
										disabled={values.authType !== 0}
									/>
									<InputGroup.Text>
										<AiOutlineMail />
									</InputGroup.Text>
								</div>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>{t("form1.labelPassword")}</Form.Label>
								<div className={styles.iconsFormRegistration}>
									<Form.Control
										type={hidePassword ? "password" : "text"}
										name="password"
										value={values.password}
										onChange={handleChange}
										// value={passwordStrong}
										// onChange={({ target }) => {
										// 	handleChange(); handlePassword(target.value);
										// }}
										isValid={touched.password && !errors.password}
										isInvalid={touched.password && errors.password}
										disabled={values.authType !== 0}
									/>
									<InputGroup.Text className={styles.passwordShown} id="basic-addon1"
										onClick={() => {
											setHidePassword(!hidePassword);
										}} >
										{!hidePassword ? <BsEye /> : <BsEyeSlash />}
									</InputGroup.Text>
								</div>

								{touched.password && errors.password ? (
									<Form.Text className="text-muted">
										<div
											dangerouslySetInnerHTML={{
												__html: t("messages.errors.passwordPatternNotMatched"),
											}}
										/>
									</Form.Text>
								) : null}

								{/* <div className="progress-bg">
									<div
										className="progress"
										style={{
											width: progressStrong,
											backgroundColor: getActiveColor(messageStrong),
										}}
									></div>
								</div>

								{passwordStrong.length !== 0 ? (
									<p className="message" style={{ color: getActiveColor(messageStrong) }}>
										Your password is {messageStrong}
									</p>
								) : null} */}
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>{t("form1.labelConfirmPassword")}</Form.Label>
								<div className={styles.iconsFormRegistration}>
									<Form.Control
										type={hideConfirmPassword ? "password" : "text"}
										name="confirmPassword"
										value={values.confirmPassword}
										onChange={handleChange}
										isValid={touched.confirmPassword && !errors.confirmPassword}
										isInvalid={touched.password && errors.confirmPassword}
										disabled={values.authType !== 0}
									/>
									<InputGroup.Text className={styles.passwordShown} id="basic-addon1"
										onClick={() => {
											setHideConfirmPassword(!hideConfirmPassword);
										}} >
										{!hideConfirmPassword ? <BsEye /> : <BsEyeSlash />}
									</InputGroup.Text>
								</div>
								{/* {passwordStrong.length !== hideConfirmPassword ? (
									<p className="message" style={{ color: getActiveColorConfirmPassword(messageStrong) }}>
										Your password and confirm Password should match
									</p>
								) : null} */}
							</Form.Group>
							<Row className="mt-4 mb-4">
								<Col className="d-grid gap-2">
									<Button
										type="submit"
										variant="primary"
										fluid
										className={`gtm_form_etapa1 ${styles.btnPrimary}`}
									>
										{t("form1.btnSubmit").toLocaleUpperCase()}
									</Button>
								</Col>
							</Row>
							{/* 
							<div className={styles.separator}>
								{t("form1.labelOr").toLocaleUpperCase()}
							</div>
							<Row>
								<Col>
									<p className="mb-1 fw-bold">{t("form1.labelSocialMedias")}</p>
								</Col>
							</Row>
							{props.authTypes
								.filter((authType) => authType.value > 0)
								.map((authType) => (
									<Row className="mt-1">
										<Col className="d-grid gap-2">
											<Button
												variant="outline-primary"
												fluid
												className={styles.btnOutlinePrimary}
												onClick={() => {
													authSocialMedia(authType.value);
												}}
											>
												{authType.image !== undefined &&
												authType.image != null ? (
													<img
														src={authType.image}
														className="position-absolute top-50 start-0 translate-middle"
														alt=""
													/>
												) : null}
												{t(`form1.authTypes.${authType.value}`)}
											</Button>
										</Col>
									</Row>
								))}
							*/}
							<Row className="mt-4">
								<Col className="text-center">
									<a href={WebappLoginPage} target="_blank" rel="noopener noreferrer" className={styles.linkLogin}>
										{t("form1.labelLinkLogin")}
									</a>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</main>
		</>
	);
}

function Form2(props) {
	const { t, i18n } = useTranslation("registration-page");
	const [showModalTerm, setShowModalTerm] = useState(false);

	const toPreviewStep = () => {
		props.setStep((prevState) => ({
			...prevState,
			current: 1,
		}));
	};

	const showTerm = () => {
		setShowModalTerm(true);
	};

	const removeNonNumeric = (text) => {
		return text?.replace(/\D/g, "");
	};

	const isValidName = (name) => {
		if (name == undefined) return false;
		const regex = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$/;
		let normalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		let result = regex.test(normalized);
		return result;
	};

	const isValidBirthDate = (birthDate) => {
		return (
			birthDate != undefined &&
			birthDate.getFullYear() <= new Date().getFullYear()
		);
	};

	const isValidDocument = (document, context) => {
		return cpf.isValid(removeNonNumeric(document));
	};

	const isValidContryCodePhone = (code) => {
		let result = Countries.find((c) => c.code == code);
		setContryCodePhoneValue(code);
		return result != undefined;
	};

	const isValidPhone = (number) => {
		number = number != undefined ? removeNonNumeric(number) : number;
		if (contryCodePhoneValue === 1) {
			return number != undefined && number.length == 10;
		} else {
			return number != undefined && number.length == 11;
		}
	};

	const schema = yup.object().shape({
		showEmailField: yup.boolean(),
		email: yup.string().when("showEmailField", {
			is: true,
			then: yup.string().required().email(),
		}),
		name: yup
			.string()
			.required()
			.trim()
			.test("test-name", "Informe o nome completo.", (value, context) =>
				isValidName(value)
			),
		document: yup
			.string()
			.required()
			.test("test-documet", "Informe um documento válido.", (value, context) =>
				isValidDocument(value, context)
			),
		contryCodePhone: yup
			.number()
			.required()
			.test(
				"test-contryCodePhone",
				"Selecione o código de telefone referente ao país correspondente.",
				(value, context) => isValidContryCodePhone(value)
			),
		celphone: yup
			.string()
			.required()
			.test("test-celphone", "Informe telefone", (value, context) =>
				isValidPhone(value)
			),
		birthdate: yup
			.date()
			.required()
			.test(
				"test-birthdate",
				"Informe data de nascimento válida.",
				(value, context) => isValidBirthDate(value)
			),
		acceptTerm: yup
			.bool()
			.required()
			.oneOf([true], "Os termos devem ser aceitos"),
	});

	const [contryCodePhoneValue, setContryCodePhoneValue] = useState(0);

	var maskUsaBr = "(99) 99999-9999";
	if (contryCodePhoneValue === 1) {
		maskUsaBr = "(999) 999-9999";
	}

	return (
		<>
			<main className={styles.formContainer}>
				<Formik
					enableReinitialize={true}
					validationSchema={schema}
					onSubmit={props.handleSubmit}
					initialValues={props.form}
				>
					{({
						handleSubmit,
						handleChange,
						setFieldValue,
						values,
						touched,
						isValid,
						errors,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							{values.showEmailField ? (
								<Form.Group className="mb-3">
									<Form.Label>
										<span className="text-danger">*</span>{" "}
										{t("form1.labelEmail")}
									</Form.Label>
									<Form.Control
										type="email"
										name="email"
										value={values.email}
										onChange={handleChange}
										isValid={touched.email && !errors.email}
										isInvalid={touched.email && errors.email}
									/>
								</Form.Group>
							) : null}
							<Form.Group className="mb-3">
								<Form.Label>
									<span className="text-danger">*</span> {t("form2.labelName")}
								</Form.Label>
								<Form.Control
									type="text"
									name="name"
									value={values.name}
									onChange={handleChange}
									isValid={touched.name && !errors.name}
									isInvalid={touched.name && errors.name}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>
									<span className="text-danger">*</span>{" "}
									{t("form2.labelDocument")}
								</Form.Label>
								<InputMask
									type="text"
									name="document"
									value={values.document}
									onChange={handleChange}
									isValid={touched.document && !errors.document}
									isInvalid={touched.document && errors.document}
									className={`form-control 
											${touched.document && !errors.document ? "is-valid" : ""}
											${touched.document && errors.document ? "is-invalid" : ""}`}
									mask="999.999.999-99"
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>
									<span className="text-danger">*</span>{" "}
									{t("form2.labelCelphone")}
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Select
										className="col-4"
										name="contryCodePhone"
										value={values.contryCodePhone}
										onChange={handleChange}
									>
										{Countries.map((country) => (
											<option value={country.code}>
												+{country.code} {country.name}
											</option>
										))}
									</Form.Select>
									<InputMask
										type="text"
										name="celphone"
										value={values.celphone}
										onChange={handleChange}
										isValid={touched.celphone && !errors.celphone}
										isInvalid={touched.celphone && errors.celphone}
										className={`form-control 
												${touched.celphone && !errors.celphone ? "is-valid" : ""}
												${touched.celphone && errors.celphone ? "is-invalid" : ""}`}
										mask={maskUsaBr}
									/>
								</InputGroup>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label>
									<span className="text-danger">*</span>{" "}
									{t("form2.labelBirthdate")}
								</Form.Label>
								<Form.Control
									type="date"
									name="birthdate"
									value={values.birthdate}
									onChange={handleChange}
									isValid={touched.birthdate && !errors.birthdate}
									isInvalid={touched.birthdate && errors.birthdate}
								/>
							</Form.Group>
							<Row className="mt-4">
								<Col>
									<div className={[styles.paddingTermCheck, "mb-2"].join(" ")}>
										<Form.Check className={styles.checkBorder} type="checkbox">
											<Form.Check.Input
												type="checkbox"
												name="acceptTerm"
												value={values.acceptTerm}
												checked={values.acceptTerm}
												onChange={handleChange}
												isInvalid={touched.acceptTerm && errors.acceptTerm}
											/>
											<Form.Check.Label
												className={
													touched.acceptTerm && errors.acceptTerm
														? "text-danger"
														: ""
												}
											>
												<span className="text-danger">*</span>{" "}
												{t("form2.checkboxes.labelAcceptTerm")}{" "}
												<Button
													variant="link"
													className={`p-0 m-0 ${touched.acceptTerm && errors.acceptTerm
														? styles.requiredAcceptTermLink
														: ""
														}`}
													onClick={showTerm}
												>
													{t("form2.checkboxes.labelTermLink")}
												</Button>
											</Form.Check.Label>
										</Form.Check>
									</div>
								</Col>
							</Row>
							<Form.Group className="mb-3">
								<div className={[styles.paddingTermCheck, "mb-2"].join(" ")}>
									<Form.Check type="checkbox" className={styles.checkBorder}>
										<Form.Check.Input
											type="checkbox"
											name="acceptAdvertising"
											value={values.acceptAdvertising}
											checked={values.acceptAdvertising}
											onChange={handleChange}
										/>
										<Form.Check.Label className="ps-2">
											{t("form2.checkboxes.labelAcceptAdvertising")}
										</Form.Check.Label>
									</Form.Check>
								</div>
							</Form.Group>
							<Row>
								<Col className="d-grid gap-2">
									<Button
										type="submit"
										variant="primary"
										className={`gtm_form_etapa2 ${styles.btnPrimary}`}
										disabled={values.sending}
									>
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
											className={
												!values.sending
													? "visually-hidden spinner-right-margin"
													: "spinner-right-margin"
											}
										/>
										{t("form2.btnSubmit").toLocaleUpperCase()}
									</Button>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</main>
		</>
	);
}