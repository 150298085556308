import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, Button, Spinner, Dropdown, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import { Formik } from 'formik';
import * as yup from 'yup';
import useDocumentTitle from "../components/useDocumentTitle";
import apijaw from "../services/api-jaw";
import "../styles/recovery-password.css";
import "../styles/pageForm.css";
import LogoAzul from "../images/logo-azul.png";
import BackImage from "../images/webdoor.png";
import "../styles/flag-icons.css";
import ChangeLanguages from '../components/changeLanguage'

export default function EmailConfirmation(props) {
    const { id } = useParams();
    const { t, i18n } = useTranslation('email-confirmation-page');

    useDocumentTitle(t('title'))

    const [reSentForm, setReSentForm] = useState({
        reSent: false
    });

    const [form, setForm] = useState({
        token: undefined,
        sending: false,
    });


    const handleresendEmailConfirmation = (values) => {
        setReSentForm({ reSent: true });
        apijaw
            .patch("/Customer/ResendEmailConfirmation", {
                customerId: id,
            },
                {
                    headers: {
                        'Accept-Language': i18n.language
                    }
                })
            .then((response) => {
                setReSentForm({ reSent: false });
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: t('reSendEmailSuccess'),
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: t('reSendEmailFail'),
                    });
                }
            })
            .catch((error) => {
                setReSentForm({ sending: false });
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('reSendEmailFail')
                    });
                }
            });
    };

    const handleSubmitThis = (values) => {
        setForm({ sending: true });
        apijaw
            .patch("/Customer/EmailConfirmation", {
                customerId: id,
                token: values.token
            },
                {
                    headers: {
                        'Accept-Language': i18n.language
                    }
                })
            .then((response) => {
                setForm({ sending: false });
                if (response.data.success === true) {
                    Swal.fire({
                        icon: 'success',
                        text: t('successMessage'),
                    }).then(function (result) {
                        window.location.assign("https://webapp.jaw.games/welcome")
                    });
                } else {
                    Swal.fire({
                        icon: 'info',
                        text: t('couldntSendRequestMessage'),
                    });
                }
            })
            .catch((error) => {
                setForm({ sending: false });
                if (error.response?.data?.errors != undefined) {
                    let message = "<p>" + error.response.data.errors.map(e => e.message).join("</p><p>") + "</p>";
                    Swal.fire({
                        icon: 'error',
                        html: message
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: t('requestFaliureMessage')
                    });
                }
            });
    };

    const schema = yup.object().shape({
        token: yup.string().required().min(6).max(6)
    });

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    return (
        <>
            <div className="login-container">
                <div
                    className="section-webdoor"
                    style={{
                        backgroundImage: `url(${BackImage})`,
                    }}
                >
                    <div className="content">

                        <Formik
                            enableReinitialize={true}
                            validationSchema={schema}
                            onSubmit={handleSubmitThis}
                            onClick={handleresendEmailConfirmation}
                            initialValues={form}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <p style={{ fontSize: "1.4em" }} className="text-center">
                                        <img src={LogoAzul} alt="" style={{ width: "27vh" }}></img>
                                    </p>
                                    <Form.Group className="mb-3" controlId="flToken">
                                        <Form.Label>{t('tokenLabel')}</Form.Label>
                                        <Form.Control
                                            autoFocus
                                            type="text"
                                            name="token"
                                            maxLength={6}
                                            value={values.token}
                                            onChange={handleChange}
                                            isInvalid={errors.token}
                                        />
                                    </Form.Group>
                                    <div className="btn-confirm-resend">
                                        <Button
                                            type="submit"
                                            className="d-grid gap-2 button gtm_confirmar_email"
                                            style={{ backgroundColor: "#26AAE2" }}
                                            size="sm"
                                            disabled={values?.token == undefined || !isValid}
                                        >
                                            <span>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className={!form.sending ? 'visually-hidden spinner-right-margin' : 'spinner-right-margin'}
                                                />
                                                {t('buttonLabel')}
                                            </span>
                                        </Button>

                                        <Button
                                            onClick={handleresendEmailConfirmation}
                                            className="d-grid gap-2 button gtm_reenvio_codigo"
                                            style={{ backgroundColor: "#26AAE2" }}
                                            size="sm"
                                        >
                                            <span>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className={!reSentForm.reSent ? 'visually-hidden spinner-right-margin' : 'spinner-right-margin'}
                                                />
                                                {t('reSendEmail')}
                                            </span>
                                        </Button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                        <div className="white-dropdown-text">
                            <ChangeLanguages />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}